import React from "react";

import DialogWithInput from "../DialogWithInput/DialogWithInput";

export default function CreateParticipantModal({
  openModal,
  handleChangeParticipantName,
}) {
  const [isModalOpen, setModalOpen] = React.useState(openModal);

  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter your name"; // Basic non-empty validation
    }
    return "";
  };

  const handleSubmit = (value) => {
    handleChangeParticipantName(value);
  };
  return (
    <div>
      <DialogWithInput
        onSubmit={handleSubmit}
        title={`Join an estimate session`}
        inputLabel="What's your name?"
        inputPlaceholder="Your name"
        buttonText="Join Session"
        buttonVariant="contained"
        open={isModalOpen}
        handleClose={() => {
          setModalOpen(!isModalOpen);
        }}
        validateInput={handleValidation}
      />
    </div>
  );
}
