import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const RoundEndedComponent = () => {
  const theme = useTheme();

  return (
    <Grid item xs={12} ml={2}>
      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 400,
          textAlign: "center",
          fontSize: { xs: "30px", md: "30px" },
        }}
      >
        Round ended!
      </Typography>
    </Grid>
  );
};

export default RoundEndedComponent;
