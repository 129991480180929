import React from "react";
import { Grid, Typography } from "@mui/material";

import HomePageFooter from "../Components/HomePageFooter";

import ThemedHeaderMenu from "../Components/ThemedHeaderMenu";

const PrivacyPolicy = () => (
  <Grid container bgcolor="#0F0819" height="100%" overflow="auto">
    <ThemedHeaderMenu />

    <div>
      <Typography
        component="h1"
        variant="h1"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "36px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Privacy Policy for Estimilo
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Introduction
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        Estimilo is currently available for free use. We are committed to
        protecting your privacy and ensuring that your personal information is
        handled in a safe and responsible manner.
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Data Collection and Usage
      </Typography>

      <Typography
        component="h3"
        variant="h3"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "22px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        General Policy
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        Estimilo respects your privacy and only collects information necessary
        to enhance your user experience. Any information provided to Estimilo is
        kept confidential and will not be disclosed to third parties without
        your explicit consent.
      </Typography>

      <Typography
        component="h3"
        variant="h3"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "22px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Cookies
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        Estimilo employs cookies for the following purposes:
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        <ol>
          <li>
            <strong>Google Analytics</strong>: We use Google Analytics to track
            and analyze website traffic. Google Analytics utilizes cookies to
            gather data on visitor numbers and behavior patterns.
          </li>
          <li>
            <strong>User Progress Tracking</strong>: Estimilo may use cookies to
            monitor your progress within a course.
          </li>
        </ol>
      </Typography>
      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        You have the option to disable cookies by adjusting the settings in your
        web browser. Please note that disabling cookies may affect the
        functionality of certain features on our platform.
      </Typography>

      <Typography
        component="h3"
        variant="h3"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "22px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Personal Information
      </Typography>
      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        Estimilo collects email addresses solely for the purpose of responding
        to user inquiries. We do not engage in unsolicited email marketing and
        will not share your email address with third parties.
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Data Security
      </Typography>
      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        We implement appropriate technical and organizational measures to
        protect your personal information against accidental or unlawful
        destruction, loss, alteration, unauthorized disclosure, or access.
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Changes to This Privacy Policy
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        Estimilo reserves the right to update or modify this Privacy Policy at
        any time. Any changes will be posted on this page with an updated
        revision date. By continuing to use our platform after any changes
        become effective, you agree to be bound by the revised Privacy Policy.
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Contact Information
      </Typography>

      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        If you have any questions or concerns regarding this Privacy Policy or
        our data handling practices, please contact us at hello[a with a monkey
        tail]estimilo[unique dot].com
      </Typography>

      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        Effective Date
      </Typography>
      <Typography
        component="p"
        variant="p"
        sx={{
          color: "white",
          textAlign: "left",
          my: { md: 1, lg: 1.5 },
          fontFamily: `'Roboto', sans-serif`,
        }}
      >
        This Privacy Policy is effective as of 12 June 2024.
      </Typography>
    </div>

    <HomePageFooter />
  </Grid>
);

export default PrivacyPolicy;
