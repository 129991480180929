import { api } from "../http";
import { apiUrls } from "../../config/Config";

class WhiteElephantServiceClass {
  /**
   * Initializes White Elephant Round
   **/
  initialize(estimateSessionUrl) {
    return api.post(
      `${apiUrls.whiteElephantSession}/${estimateSessionUrl}/initialize`,
      {
        estimateSessionUrl: estimateSessionUrl,
      }
    );
  }

  /**
   * Updates participant status in White Elephant Round
   **/
  updateParticipantStatus(estimateSessionUrl, participantId, newStatus) {
    return api.put(
      `${apiUrls.whiteElephantSession}/${estimateSessionUrl}/participants/${participantId}/status`,
      newStatus
    );
  }

  getParticipantStatus(estimateSessionUrl, participantId) {
    return api.get(
      `${apiUrls.whiteElephantSession}/${estimateSessionUrl}/participants/${participantId}/status`
    );
  }

  fetchParticipants(estimateSessionUrl) {
    return api.get(
      `${apiUrls.whiteElephantSession}/${estimateSessionUrl}/participants`
    );
  }

  /**
   * Creates a new Participant, in a given role, to an WhiteElephant Estimate Session identified by  it's URL, given a name
   **/
  createNewParticipantEstimateSession(
    estimateSessionUrl,
    participantName,
    participantRole
  ) {
    return api.post(
      `${apiUrls.whiteElephantSession}/${estimateSessionUrl}/participants`,
      {
        name: participantName,
        role: participantRole,
      }
    );
  }
}

const WhiteElephantService = new WhiteElephantServiceClass();

export default WhiteElephantService;
