import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Typography, Button, Grid, Box, Snackbar, Alert } from "@mui/material";

import { useBeforeunload } from "react-beforeunload";

import CreateParticipantModal from "../../Components/genericTool/CreateParticipantModal";
import ParticipantList from "../../Components/genericTool/ParticipantList";
import CreateItemModal from "../../Components/genericTool/CreateItemModal";
import StoriesList from "../../Components/genericTool/StoriesList";

import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";
import HomePageFooter from "../../Components/HomePageFooter";

import CategoryOfComplexityList from "../../Components/ComplexityBuckets/CategoryOfComplexityList";
import EstimationRoundForComplexityBuckets from "../../Components/ComplexityBuckets/EstimationRoundForComplexityBuckets";
import CreateCategoryOfComplexityModal from "../../Components/ComplexityBuckets/CreateCategoryOfComplexityModal";

import EstimateSessionDataService from "../../service/EstimateSessionDataService";
import { api } from "../../service/http";
import Config, { apiUrls } from "../../config/Config";
import { isParticipantTheScrumMaster } from "../../Utils/Utils";

import { useTheme } from "@mui/material/styles";
import ChatBotComponent from "../../Components/ChatBotComponent";
import ImportItemsFromJiraModal from "../../Components/genericTool/ImportItemsFromJiraModal";
import PremiumContent from "../../Components/PremiumContent";

export default function EstimateSession({
  updateParticipantData,
  participant,
  scaleValue,
}) {
  let { url } = useParams();
  let navigate = useNavigate();
  const theme = useTheme();
  const estimateSessionUrl = url;

  const [stories, setStories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [categoriesOfComplexity, setCategoriesOfComplexity] = useState([]);
  const [activeRoundObj, setActiveRoundObj] = useState([]);

  // States for Snackbar
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Heartbeat mechanism

  // the timestamp of the last update of the Estimate Session, which would require re-rendering
  const [latestUpdateTimestamp, setLatestUpdateTimestamp] = useState();

  const reCalculate = useCallback(() => {
    // Loads from BE all Items in the Estimate Session
    const fetchStories = async () => {
      const result =
        await EstimateSessionDataService.retrieveAllItemsFromSession(
          estimateSessionUrl
        );
      setStories(result.data);
    };
    fetchStories();

    // Loads from BE all Participants in the Estimate Session
    const fetchParticipants = async () => {
      const result =
        await EstimateSessionDataService.retrieveAllParticipantsFromSession(
          estimateSessionUrl
        );
      setParticipants(result.data);

      //Check if the current Participant exists in the Participant List. If not, redirect to home page
      if (participant.id && !participantExists(result.data, participant.id)) {
        navigate("/complexityBuckets");
      }
    };
    fetchParticipants();

    const fetchCategoriesOfComplexity = async () => {
      const result =
        await EstimateSessionDataService.retrieveAllCategoriesOfComplexityFromSession(
          estimateSessionUrl
        );
      setCategoriesOfComplexity(result.data);
    };
    fetchCategoriesOfComplexity();

    //Loads from BE the Estimate Round
    const fetchActiveRound = async () => {
      const result =
        await EstimateSessionDataService.retrieveActiveRoundFromSession(
          estimateSessionUrl
        );
      setActiveRoundObj(result.data);
    };
    fetchActiveRound();
  }, [estimateSessionUrl, navigate, participant.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the heartbeat API
        const result = await EstimateSessionDataService.heartbeat(
          estimateSessionUrl
        );

        // If there's no latest timestamp or if the data is newer, recalculate
        if (!latestUpdateTimestamp || result.data > latestUpdateTimestamp) {
          reCalculate();
          setLatestUpdateTimestamp(result.data);
        }
      } catch (err) {

      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, [estimateSessionUrl, latestUpdateTimestamp, reCalculate]);

  // END Heartbeat mechanism

  //check if a Participant with a give ID exists in the list of Participants to the Estimate Session
  const participantExists = (data, id) => {
    data.filter((el) => el.id === id);
    return data.length > 0;
  };

  const [appState, setAppState] = React.useState({
    sessionExists: false,
  });

  useEffect(() => {
    // Checks if an estimate session, identified by it's URL, exists
    setAppState({ sessionExists: false });

    api
      .get(`${apiUrls.retrieveEstimateSession}/${estimateSessionUrl}`)
      .then((res) => {
        setAppState({ sessionExists: true });
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          //Unauthorized access - Redirecting to login
          window.location.href = "/login";
        }
        if (error.response.status === 404) {
          setAppState({ sessionExists: false });
        }
      });
  }, [estimateSessionUrl]);

  const createNewParticipantEstimateSession = (participantName) => {
    // create the Participant to the Estimate Session with the role of TEAM MEMBER
    EstimateSessionDataService.createNewParticipantEstimateSession(
      estimateSessionUrl,
      participantName,
      Config.DEFAULT_PARTICIPANT_ROLE
    ).then((result) => {
      updateParticipantData({
        name: participantName,
        id: result.data,
        role: Config.DEFAULT_PARTICIPANT_ROLE,
      });
      reCalculate();
    });
  };

  //deletes the participate to the Estimate Session
  const deleteParticipantFromEstimateSession = async (
    participantIdToDelete
  ) => {
    await EstimateSessionDataService.deleteParticipantFromEstimateSession(
      estimateSessionUrl,
      participantIdToDelete
    );
    if (participant.id === participantIdToDelete) {
      navigate("/complexityBuckets");
    }
    reCalculate();
  };

  useBeforeunload(() => {
    deleteParticipantFromEstimateSession(participant.id);
  });

  //Set the Item to be estimated and initializes the Round
  const initializeRoundForComplexityBuckets = (itemId) => {
    EstimateSessionDataService.initializeRound(estimateSessionUrl, itemId).then(
      (response) => {
        reCalculate();
      }
    );
  };

  const passOpenModal = () => {
    if (participant.role === "SCRUM_MASTER") {
      return false;
    }
    return true;
  };

  const createNewCategoryOfComplexity = (categoryOfComplexityName) => {
    EstimateSessionDataService.createNewCategoryOfComplexityInEstimateSession(
      estimateSessionUrl,
      categoryOfComplexityName
    ).then((response) => {
      setCategoriesOfComplexity((prevCategories) => [
        ...prevCategories,
        response.data,
      ]);
    });
  };

  const deleteStory = (id) => {
    EstimateSessionDataService.deleteItemFromEstimateSession(
      estimateSessionUrl,
      id
    ).then((response) => {
      reCalculate();
    });
  };

  const importItemsFromJira = async (jiraCustomJql) => {
    try {
      await EstimateSessionDataService.importItemsFromJira(
        estimateSessionUrl,
        jiraCustomJql
      );
      // Handle the response after the request is successful
      reCalculate();
      // Show success message
      setSuccessMessage("Items imported successfully!");
    } catch (error) {
      // Show error message
      setErrorMessage("Error importing items from Jira");
    }
  };

  const deleteCategoryOfComplexity = (id) => {
    EstimateSessionDataService.deleteCategoryOfComplexityFromEstimateSession(
      estimateSessionUrl,
      id
    ).then((response) => {
      reCalculate();
    });
  };

  const deleteParticipantFromRound = (id) => {
    EstimateSessionDataService.deleteParticipantFromRound(
      estimateSessionUrl,
      activeRoundObj.id,
      id
    ).then((response) => {
      reCalculate();
    });
  };

  const giveEstimate = (value) => {
    EstimateSessionDataService.giveEstimate(
      activeRoundObj.id,
      participant.id,
      value
    ).then((response) => {
      reCalculate();
    });
  };

  const acceptEstimate = () => {
    EstimateSessionDataService.acceptConsensusEstimate(activeRoundObj.id).then(
      (response) => {
        reCalculate();
      }
    );
  };

  // Handlers to close Snackbar
  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <Grid
      container
      height={1}
      sx={{ bgcolor: theme.palette.secondary.main }}
      overflow="auto"
    >
      <ThemedHeaderMenu themeSwitch />

      {!appState.sessionExists && (
        <Grid
          item
          xs={12}
          justifyContent="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="p" sx={{ my: 10 }}>
            No estimate session with this ID exists
          </Typography>

          <Button
            variant="contained"
            color="success"
            component={Link}
            to={"/complexityBuckets"}
          >
            Start a new Estimate Session
          </Button>
        </Grid>
      )}

      {appState.sessionExists && (
        <Grid item container>
          <Grid item md={8} px={4} xs={12} mt={{ xs: 1, md: 3 }}>
            <EstimationRoundForComplexityBuckets
              currentRound={activeRoundObj}
              currentParticipantId={participant.id}
              deleteParticipant={(id) => deleteParticipantFromRound(id)}
              giveEstimate={giveEstimate}
              scaleValue={scaleValue}
              acceptEstimate={acceptEstimate}
              categoriesOfComplexity={categoriesOfComplexity}
            />
          </Grid>
          <Grid item xs={12} md={4} mt={-3}>
            <Box mt={3} display="flex" justifyContent="space-between">
              <CreateItemModal />
              <PremiumContent>
                <ImportItemsFromJiraModal onSubmit={importItemsFromJira} />
              </PremiumContent>
            </Box>
            <StoriesList
              initializeRound={initializeRoundForComplexityBuckets}
              deleteStory={deleteStory}
              currentParticipantId={participant.id}
              stories={stories}
              participants={participants}
            />
            <CreateParticipantModal
              openModal={passOpenModal()}
              handleChangeParticipantName={(name) => {
                createNewParticipantEstimateSession(name);
              }}
            />
            <ParticipantList
              currentParticipantId={participant.id}
              participants={participants}
              deleteParticipant={(id) =>
                deleteParticipantFromEstimateSession(id)
              }
              navigateToURL="/complexityBuckets"
            />

            {
              isParticipantTheScrumMaster(participant.role) ? (
              <CreateCategoryOfComplexityModal
                onSubmit={createNewCategoryOfComplexity}
              />
            ) : (
              ""
            )}
            <CategoryOfComplexityList
              categoriesOfComplexity={categoriesOfComplexity}
              deleteCategoryOfComplexity={deleteCategoryOfComplexity}
              currentParticipantId={participant.id}
              participants={participants}
            />
            {/*<ChatBotComponent*/}
            {/*  activeRoundObj={activeRoundObj}*/}
            {/*  scaleValue={scaleValue}*/}
            {/*  items={stories}*/}
            {/*/>*/}
          </Grid>
        </Grid>
      )}

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <HomePageFooter />
    </Grid>
  );
}
