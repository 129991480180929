import React, { useState, useEffect } from 'react';

import {Box, Container, Grid, IconButton, Paper, Typography} from '@mui/material';
import ThemedHeaderMenu from '../Components/ThemedHeaderMenu';
import HomePageFooter from '../Components/HomePageFooter';

import { useTheme } from '@mui/material/styles';
import EstimateSessionDataService from "../service/EstimateSessionDataService";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CreateUserModal from "../Components/ManageTenant/CreateUserModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../Components/DialogConfirm/DialogConfirm";


const ManageTenant = () => {
    const theme = useTheme();

    const [tenantUsers, setTenantUsers] = useState([]); // State to store the fetched data

    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [selectedUserToDelete, setSelectedUserToDelete] = React.useState();



    const retrieveTenantUsers = async () => {
        const response = await  EstimateSessionDataService.retrieveAllUsers();
        setTenantUsers(response.data);
    };

    // Fetch users on component mount
    useEffect(() => {
        retrieveTenantUsers();
    }, []);

    const createNewUser = (userEmail) => {
        EstimateSessionDataService.createNewUser(userEmail).then(
            (response) => {
                setTenantUsers(prevUsers => [...prevUsers, response.data]);
            }
        );
    };

    const handleDeleteUser = (id) => {
            EstimateSessionDataService.deleteUser(
                id
            ).then((response) => {
                retrieveTenantUsers();
            });


        setConfirmDeleteOpen(false);
    }

    return (

        <Grid
            container
            height={1}
            sx={{ bgcolor: theme.palette.secondary.main }}
            overflow="auto"
        >
            <ThemedHeaderMenu themeSwitch title="Manage Tenant" />

            <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>


                <Typography variant="h4" gutterBottom>
                    Users
                </Typography>

                <CreateUserModal
                    onSubmit={createNewUser}
                />

                {/* User Table */}
                <TableContainer component={Paper}>
                    <Table aria-label="tenant users table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Creation Date</TableCell>
                                <TableCell align="center">Last Login</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenantUsers.map((user) => (

                                <TableRow key={user.id}>

                                    <TableCell align="center">
                                        <IconButton color='success' onClick={() => {setSelectedUserToDelete(user); setConfirmDeleteOpen(true)}} >
                                            <DeleteIcon />
                                        </IconButton >
                                    </TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">
                                        {
                                        new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }).format(new Date(user.creationDate))
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }).format(new Date(user.lastLogin))
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            <DialogConfirm
                                title={`Are you sure you want to remove user: ${selectedUserToDelete?.email}?`}
                                open={confirmDeleteOpen}
                                onConfirm={() => handleDeleteUser(selectedUserToDelete?.id)}
                                onReject={() => {setConfirmDeleteOpen(false)}}
                                confirmText='YES'
                                rejectText='NO'
                            />
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>

            <HomePageFooter />

        </Grid>


    );
}

export default ManageTenant;