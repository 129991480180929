import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import EstimateSessionDataService from "../../service/EstimateSessionDataService";
import Config, { EstimateSessionType } from "../../config/Config";

import DialogWithInput from "../DialogWithInput/DialogWithInput";
import WhiteElephantService from "service/WhiteElephant/WhiteElephantService";

export default function CreateParticipantAndNewEstimateSessionForm({
  updateParticipantData,
  navigateTo,
  estimateSessionType,
}) {
  const [isModalOpen, setModalOpen] = useState(true);

  let navigate = useNavigate();

  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter your name"; // Basic non-empty validation
    }
    return "";
  };

  const handleSubmit = (participantName) => {
    //randomly generate an URL
    const generatedUrl = generateNumber(1000000, 10000000);

    initEstimateSessionAndParticipant(generatedUrl, participantName);
  };

  async function initEstimateSessionAndParticipant(
    generatedUrl,
    participantName
  ) {
    try {

      // create the Estimate Session
      await createNewEstimateSession(generatedUrl);

      // create the Participant as the SCRUM MASTER
      createNewParticipantEstimateSession(
        generatedUrl,
        participantName,
        Config.SCRUM_MASTER_ROLE
      );

      navigate(navigateTo + generatedUrl);
    } catch (err) {
      console.log("Init Failed");
    }
  }

  /**
   * Calls the API for creating a new estimate session
   **/
  const createNewEstimateSession = (url) => {
    let result;
    try {
      result = EstimateSessionDataService.createNewEstimateSession(
        url,
        estimateSessionType
      );
    } catch (err) {
      console.log("New estimate Session Failed");
    }
    return result;
  };

  /**
   * Calls the API for creating a new Participant to the Estimate Session
   **/

  const createNewParticipantEstimateSession = (
    estimateSessionUrl,
    participantName,
    participantRole
  ) => {
    switch (estimateSessionType) {
      case EstimateSessionType.PLANNING_POKER: {
        EstimateSessionDataService.createNewParticipantEstimateSession(
            estimateSessionUrl,
            participantName,
            participantRole
        ).then((result) => {
          updateParticipantData({
            name: participantName,
            role: participantRole,
            id: result.data,
          });
        });
        break;
      }
      case  EstimateSessionType.COMPLEXITY_BUCKETS: {
        EstimateSessionDataService.createNewParticipantEstimateSession(
            estimateSessionUrl,
            participantName,
            participantRole
        ).then((result) => {
          updateParticipantData({
            name: participantName,
            role: participantRole,
            id: result.data,
          });
        });
        break;
      }
       case  EstimateSessionType.WHITE_ELEPHANT_SIZING: {
        WhiteElephantService.createNewParticipantEstimateSession(
          estimateSessionUrl,
          participantName,
          participantRole
        ).then((result) => {
          updateParticipantData({
            name: participantName,
            role: participantRole,
            id: result.data,
          });
        });
        break;
      }
    }
  };

  /**
   * Generates a random number between min and max
   **/
  const generateNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <Container maxWidth="sm">
      <DialogWithInput
        onSubmit={handleSubmit}
        title="Start an estimate session"
        inputLabel="What's your name?"
        inputPlaceholder="Your name"
        buttonText="Start Session"
        buttonVariant="contained"
        open={isModalOpen}
        handleClose={() => {
          navigate("/");
          setModalOpen(!isModalOpen);
        }}
        validateInput={handleValidation}
      />
    </Container>
  );
}
