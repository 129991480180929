import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";
import HomePageFooter from "../../Components/HomePageFooter";
import CourseContent from "../../Components/Course/CourseContent";
import YouTubePlayer from "../../Components/Course/YoutubePlayer";
import { useTheme } from "@mui/material/styles";
import { courseContainerStyle } from "./Course.style";

const Course = () => {
  const [currentVideo, setCurrentVideo] = useState(
    "Nothing is playing right now."
  );
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...courseContainerStyle,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Box mr={10}>
        <ThemedHeaderMenu themeSwitch />
      </Box>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} flex={1}>
        <Box flex={1}>
          <CourseContent
            setCurrentVideo={setCurrentVideo}
            currentVideo={currentVideo}
          />
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          flex={3}
          display="flex"
        >
          {typeof currentVideo === "string" && (
            <Typography variant="p" component="p" color="primary">
              {currentVideo}
            </Typography>
          )}

          <YouTubePlayer
            video={typeof currentVideo !== "string" ? currentVideo : null}
            width="90%"
            height="60%"
          />
        </Box>
      </Box>
      <Box>
        <HomePageFooter />
      </Box>
    </Box>
  );
};

export default Course;
