import { api } from "./http";
import { apiUrls } from "../config/Config";

class EstimateSessionDataServiceClass {
  /**
   * Creates a new Estimate Session of a specified type, identified by  the specified URL
   **/
  async createNewEstimateSession(estimateSessionUrl, estimateSessionType) {
    try {
      return await api.post(apiUrls.createNewEstimateSession, {
        url: estimateSessionUrl,
        estimateSessionType: estimateSessionType,
      });
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates a new Participant, in a given role, to an Estimate Session identified by  it's URL, given a name
   **/
  async createNewParticipantEstimateSession(
    estimateSessionUrl,
    participantName,
    participantRole
  ) {
    try {
      return await api.post(
        `${apiUrls.createNewParticipantToEstimateSession}/${estimateSessionUrl}`,
        {
          name: participantName,
          role: participantRole,
        }
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Deletes a Participant, identified by it's name, from the Estimate Session
   **/
  async deleteParticipantFromEstimateSession(
    estimateSessionUrl,
    participantId
  ) {
    try {
      return await api.delete(
        `${apiUrls.deleteParticipantFromEstimateSession}/${estimateSessionUrl}/${participantId}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates a new Item to an Estimate Session identified by  it's URL, given a name
   **/
  async createNewItemInEstimateSession(estimateSessionUrl, itemName) {
    try {
      return await api.post(
        `${apiUrls.createNewItemToEstimateSession}/${estimateSessionUrl}`,
        { name: itemName }
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates a new Category of Complexity to an Estimate Session identified by  it's URL, given a name
   **/
  async createNewCategoryOfComplexityInEstimateSession(
    estimateSessionUrl,
    categoryOfComplexityName
  ) {
    try {
      return await api.post(
        `${apiUrls.createNewCategoryOfComplexityToEstimateSession}/${estimateSessionUrl}`,
        { name: categoryOfComplexityName }
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates a Round for the given Estimate Session, identified by  it's URL, and an Item, identified by it's ID
   **/
  async initializeRound(estimateSessionUrl, itemIdToEstimate) {
    try {
      return await api.post(
        `${apiUrls.initializeRound}/${estimateSessionUrl}/${itemIdToEstimate}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async giveEstimate(roundId, participantId, givenEstimate) {
    try {
      return await api.post(
        `${apiUrls.giveEstimate}/${roundId}/${participantId}/${givenEstimate}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async removeEstimate(roundId, participantId) {
    try {
      return await api.post(
        `${apiUrls.removeEstimate}/${roundId}/${participantId}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async acceptConsensusEstimate(roundId) {
    try {
      return await api.post(`${apiUrls.acceptConsensusEstimate}/${roundId}`);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async deleteItemFromEstimateSession(estimateSessionUrl, itemIdToDelete) {
    try {
      return await api.delete(
        `${apiUrls.deleteFromSession}/${estimateSessionUrl}/${itemIdToDelete}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async deleteCategoryOfComplexityFromEstimateSession(
    estimateSessionUrl,
    categoryOfComplexityIdToDelete
  ) {
    try {
      return await api.delete(
        `${apiUrls.deleteCategoryOfComplexityFromSession}/${estimateSessionUrl}/${categoryOfComplexityIdToDelete}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async deleteParticipantFromRound(
    estimateSessionUrl,
    roundId,
    participantIdToDelete
  ) {
    try {
      return await api.delete(
        `${apiUrls.deleteParticipant}/${estimateSessionUrl}/${roundId}/${participantIdToDelete}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve the active Round from the Estimate Session
   */
  async retrieveActiveRoundFromSession(estimateSessionUrl) {
    try {
      return await api.get(
        `${apiUrls.retrieveActiveRoundFromSession}/${estimateSessionUrl}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve all Items from the Estimate Session
   */
  async retrieveAllItemsFromSession(estimateSessionUrl) {
    try {
      return await api.get(
        `${apiUrls.retrieveAllItemsFromSession}/${estimateSessionUrl}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve all Participants from the Estimate Session
   */
  async retrieveAllParticipantsFromSession(estimateSessionUrl) {
    try {
      return await api.get(
        `${apiUrls.retrieveAllParticipantsFromSession}/${estimateSessionUrl}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve all Categories of Complexity from the Estimate Session
   */
  async retrieveAllCategoriesOfComplexityFromSession(estimateSessionUrl) {
    try {
      return await api.get(
        `${apiUrls.retrieveAllCategoriesOfComplexityFromSession}/${estimateSessionUrl}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve all Estimate Sessions (for the Tenant)
   */
  async retrieveAllEstimateSessions() {
    try {
      return await api.get(`${apiUrls.retrieveAllEstimateSessions}`);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call to retrieve Items from Jira
   */
  async importItemsFromJira(estimateSessionUrl, jiraCustomJql) {
    try {
      return await api.get(
        `${
          apiUrls.retrieveItemsFromJira
        }/${estimateSessionUrl}?jiraCustomJql=${encodeURIComponent(
          jiraCustomJql
        )}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Call for the heartbeat mechanism
   */
  async heartbeat(estimateSessionUrl) {
    try {
      return await api.get(`${apiUrls.heartbeatUrl}/${estimateSessionUrl}`);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Retrieves the logged-in User information
   *
   * NOT USED FOR NOW
   **/
  async retrieveLoggedInUserInfo(estimateSessionUrl, estimateSessionType) {
    try {
      return await api.get(apiUrls.retrieveLoggedInUserInfo);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Soft deletes the logged in user
   */
  async deleteLoggedInUser() {
    try {
      return await api.delete(apiUrls.deleteLoggedInUser);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Retrieves the Tenant's Users
   */
  async retrieveAllUsers() {
    try {
      return await api.get(apiUrls.getAllUsers);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates a new User given its email
   **/
  async createNewUser(userEmail) {
    try {
      return await api.post(`${apiUrls.createNewUser}/${userEmail}`);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Deletes an User
   */
  async deleteUser(userId) {
    try {
      return await api.delete(`${apiUrls.deleteUser}/${userId}`);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Retrieves the current User's preferences
   */
  async getUserPreferences() {
    try {
      return await api.get(apiUrls.getUserPreferences);
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  /**
   * Creates/Updates a User's preferences
   **/
  async saveUserPreference(preferenceKey, preferenceValue) {
    try {
      return await api.post(
        `${apiUrls.saveUserPreference}/${preferenceKey}?preferenceValue=${preferenceValue}`
      );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
}

const EstimateSessionDataService = new EstimateSessionDataServiceClass();

function handleError(err) {
  if (err.response && err.response.status === 401) {
    console.log("Unauthorized access - Redirecting to login");
    window.location.href = "/login";
  } else {
    console.error("Operation failed");
  }
}

export default EstimateSessionDataService;
