import React from "react";

import { IconButton, ListItem, List, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ListCard from "../ListCard/ListCard";
import DialogConfirm from "../DialogConfirm/DialogConfirm";

import { isParticipantTheScrumMaster } from "../../Utils/Utils";

const CategoryOfComplexityList = ({
  categoriesOfComplexity,
  deleteCategoryOfComplexity,
  currentParticipantId,
  participants,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [
    selectedCategoryOfComplexityToDelete,
    setSelectedCategoryOfComplexityToDelete,
  ] = React.useState();

  const currentParticipant =
    participants && participants.filter((p) => p.id === currentParticipantId);
  const currentParticipantRole =
    currentParticipant && currentParticipant[0]?.role;

  const handleDeleteCategoryOfComplexity = (id) => {
    deleteCategoryOfComplexity(id);
    setConfirmDeleteOpen(false);
  };

  return (
    <ListCard title="Categories of Complexity">
      <List>
        {categoriesOfComplexity &&
          categoriesOfComplexity.map((s) => (
            <ListItem
              key={`${s.id}-${s.name}`}
              sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}
            >
              <div
                style={{
                  display: "flex",
                  textOverflow: "ellipsis",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                {isParticipantTheScrumMaster(currentParticipantRole) ? (
                  <IconButton
                    color="success"
                    onClick={() => {
                      setSelectedCategoryOfComplexityToDelete(s);
                      setConfirmDeleteOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  ""
                )}
                <Typography component="p">{s.name}</Typography>
              </div>

              <DialogConfirm
                title={`Are you sure you want to remove this Category of Complexity: ${selectedCategoryOfComplexityToDelete?.name}?`}
                open={confirmDeleteOpen}
                onConfirm={() =>
                  handleDeleteCategoryOfComplexity(
                    selectedCategoryOfComplexityToDelete?.id
                  )
                }
                onReject={() => {
                  setConfirmDeleteOpen(false);
                }}
                confirmText="YES"
                rejectText="NO"
              />
            </ListItem>
          ))}
      </List>
    </ListCard>
  );
};

export default CategoryOfComplexityList;
