import React from "react";

import { Button, Grid } from "@mui/material";
import Timer from "./Timer";

const WhiteElephantEstimationBoardButtons = ({
  shouldShowStartButton,
  shouldShowEndTurnButton,
  shouldShowPassButton,
  actionMethods,
}) => (
  <>
    {shouldShowEndTurnButton || shouldShowPassButton ? (
      <Grid
        item
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={0}
        py={0}
      >
        <Timer
          initialMinute={1}
          timerEnd={() => {
            actionMethods.endTurn();
          }}
        />
      </Grid>
    ) : (
      <></>
    )}
    <Grid
      item
      container
      px={4}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      {shouldShowStartButton && (
        <Grid item>
          <Button
            key="startRoundButton"
            variant="contained"
            color="success"
            sx={{ mx: 1, minWidth: 120 }}
            onClick={actionMethods.startRound}
          >
            Start Round
          </Button>
        </Grid>
      )}
      {shouldShowPassButton && (
        <Grid item>
          <Button
            key="passTurnButton"
            variant="contained"
            color="success"
            sx={{ mx: 1, minWidth: 120 }}
            onClick={actionMethods.passTurn}
          >
            Pass
          </Button>
        </Grid>
      )}
      {shouldShowEndTurnButton && (
        <Grid item>
          <Button
            key="finishTurnButton"
            variant="contained"
            color="success"
            sx={{ mx: 1, minWidth: 120 }}
            onClick={actionMethods.endTurn}
          >
            Finish turn
          </Button>
        </Grid>
      )}
    </Grid>
  </>
);

export default WhiteElephantEstimationBoardButtons;
