import { Grid, Box, Button, Typography } from "@mui/material";
import ComingSoonTag from "./ComingSoonTag";
import PremiumPlanTag from "./PremiumPlanTag";

const FullWidthCard = ({
  backgroundImage,
  comingSoon = false,
  premiumPlan = false,
  theme = "dark",
  bigTitle = "",
  title = "",
  subtitle = "",
  description = "",
  descriptionList = [],
  buttonTitle = "",
  buttonClick,
  height = "100%",
  mt,
  pt,
  mb,
  pb,
  ml,
  alignText,
}) => (
  <Grid
    container
    sx={{
      backgroundColor: backgroundImage ? "transparent" : "#25222E",
      p: { xs: 2, md: 4 },
      pt,
      mx: { xs: 1, md: 3 },
      mt,
      mb,
      pb,
      borderRadius: 10,
      height,
      ml,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    <Grid item xs={12} md={6}>
      {alignText === "left" && (
        <>
          {comingSoon && <ComingSoonTag theme={theme} />}
          {premiumPlan && <PremiumPlanTag theme={theme} />}
          {bigTitle && (
            <Typography
              component="h2"
              variant="h2"
              sx={{
                color: "white",
                fontWeight: 800,
                textAlign: "left",
                fontSize: { xs: "30px", md: "32px", lg: "42px", xl: "60px" },
                pt: { xs: 1, md: 3 },
              }}
            >
              {bigTitle}
            </Typography>
          )}
          {title && (
            <Typography
              component="h2"
              variant="h2"
              sx={{
                color: "white",
                fontWeight: 800,
                textAlign: "left",
                fontSize: { xs: "28px", md: "34px", lg: "42px" },
                pt: 5,
              }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              component="p"
              variant="p"
              sx={{
                color: "white",
                textAlign: "left",
                mb: 1,
                fontSize: { xs: "18px", md: "20px", lg: "24px", xl: "32px" },
                my: 3,
                fontFamily: `'Roboto', sans-serif`,
              }}
            >
              {subtitle}
            </Typography>
          )}
          {description && (
            <Box
              sx={{
                color: "white",
                fontSize: { xs: "16px", lg: "18px", xl: "22px" },
                fontFamily: `'Roboto', sans-serif`,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {descriptionList &&
            descriptionList.map((item) => (
              <Box
                key={item.id}
                sx={{ display: "flex", gridRow: 1, gridColumn: 2, mb: 3 }}
              >
                <img
                  src={item.icon}
                  alt={`icon for ${item.text}`}
                  style={{ marginRight: "10px" }}
                />
                <Box
                  sx={{
                    color: "white",
                    fontSize: { xs: "16px", lg: "18px", xl: "22px" },
                    fontFamily: `'Roboto', sans-serif`,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </Box>
            ))}
          {buttonTitle && (
            <Button
              sx={{
                bgcolor: "#BB86FC",
                borderRadius: "10px",
                color: "white",
                fontWeight: 500,
                paddingLeft: "35px",
                paddingRight: "35px",
                display: "flex",
                minWidth: { md: "230px", xs: "50px" },
                mb: 2,
              }}
              onClick={buttonClick}
            >
              {buttonTitle}
            </Button>
          )}
        </>
      )}
    </Grid>
    <Grid item xs={12} md={6}>
      {alignText === "right" && (
        <>
          {comingSoon && <ComingSoonTag theme={theme} />}
          {premiumPlan && <PremiumPlanTag theme={theme} />}
          {bigTitle && (
            <Typography
              component="h2"
              variant="h2"
              sx={{
                color: "white",
                fontWeight: 800,
                textAlign: "left",
                fontSize: { xs: "30px", md: "32px", lg: "42px", xl: "60px" },
                pt: 3,
              }}
            >
              {bigTitle}
            </Typography>
          )}
          {title && (
            <Typography
              component="h2"
              variant="h2"
              sx={{
                color: "white",
                fontWeight: 800,
                textAlign: "left",
                fontSize: "28px",
                pt: 5,
              }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              component="p"
              variant="p"
              sx={{
                color: "white",
                textAlign: "left",
                mb: 1,
                fontSize: { xs: "18px", md: "20px", lg: "24px", xl: "32px" },
                my: 3,
                fontFamily: `'Roboto', sans-serif`,
              }}
            >
              {subtitle}
            </Typography>
          )}
          {description && (
            <div
              style={{ color: "white" }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {descriptionList &&
            descriptionList.map((item) => (
              <Box
                key={item.id}
                sx={{ display: "flex", gridRow: 1, gridColumn: 2, mb: 3 }}
              >
                <img
                  src={item.icon}
                  alt={`icon for ${item.text}`}
                  style={{ marginRight: "10px" }}
                />
                <Box
                  sx={{ color: "white", fontSize: { xs: "18px", lg: "22px" } }}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </Box>
            ))}
          {buttonTitle && (
            <Button
              sx={{
                bgcolor: "#BB86FC",
                borderRadius: "10px",
                color: "white",
                fontWeight: 500,
                paddingLeft: "35px",
                paddingRight: "35px",
                display: "flex",
                minWidth: { md: "230px", xs: "50px" },
                mb: 2,
              }}
            >
              {buttonTitle}
            </Button>
          )}
        </>
      )}
    </Grid>
  </Grid>
);

export default FullWidthCard;
