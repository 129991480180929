import React, { useEffect, useState } from 'react';
import {Grid, Button, TextField, Typography, Box, Paper, useTheme} from '@mui/material';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import HomePageFooter from '../Components/HomePageFooter';
import logo from '../images/logo.png';
import {inputStyle} from "../Components/DialogWithInput/DialogWithInput.style";

import GoogleButton from 'react-google-button'
import UserManagementService from "../service/UserManagementService";
import SystemConfig from "../config/SystemConfig";

const SignupPage = () => {


    const theme = useTheme();
    const [email, setEmail] = useState('');
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation(); // Hook to detect the current URL

    // State to hold the extracted estimateSessionUrl
    const [estimateSessionUrl, setEstimateSessionUrl] = useState(null);


    // Check for specific error messages (e.g., UserNotFound)
    useEffect(() => {

        const urlParam = searchParams.get('estimateSessionUrl');
        if (urlParam) {
            setEstimateSessionUrl(urlParam); // Store the extracted parameter in state
        }

        const errorParam = searchParams.get("error");

        if (errorParam === "UserNotFound") {
            setErrorMessage("No account found with the provided Google account.");
        }
        // Handle general unexpected errors if redirected to the /error page
        else if (location.pathname === "/error") {
            setErrorMessage("An unexpected error occurred. Please try again later.");
        }
    }, [searchParams, location]);

    // Handle Google login redirect
    const handleGoogleLogin = () => {

        if (estimateSessionUrl) {
            // Set the estimateSessionUrl in a cookie to be retrieve in Backend and removed immediately after
            document.cookie = `estimateSessionUrl=${encodeURIComponent(estimateSessionUrl)}; path=/;`;
        }

        window.location.href = SystemConfig.getBaseUrl() + '/api' + "/oauth2/authorization/google"; // Redirect to Google SSO
    };

    // Handle Company SSO login
    const handleCompanySSOLogin = () => {

        const error = validateInput ? validateInput(email) : "";
        if (error) {
            setErrorMessage(error);
            return;
        }

        initLogin(email);
    };

    const validateInput = (input) => {
        if (!input.trim()) {
            return "Please enter some text."; // Basic non-empty validation
        }
        if (!input.includes('@')) {
            return "Input must include an '@' symbol."; // Custom validation logic
        }
        return "Internal error. Please log in with your Google account.";
    };

    async function initLogin(loginEmail) {
        try {
            await doLogin(loginEmail);
        }
        catch (err) {
            console.error('Login Failed');
        }

    }



    const doLogin = (loginEmail) => {
        UserManagementService.doLogin(loginEmail).then((result) => {
        });
    }

    const handleInputChange = (event) => {
        if (errorMessage) setErrorMessage("");
        setEmail(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleCompanySSOLogin(event);
        }
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" bgcolor="#0F0819" height="100%">

            {/* Logo Section */}
            <Box textAlign="center" mb={2}>

                <Grid container>
                    <Grid item xs={1} alignContent="center" justifyContent="center">
                        <Link
                            to="/"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                            }}
                        >
                            <img src={logo} alt="Estimilo Logo" style={{ height: "100px" }}  />
                            <Typography
                                variant="p"
                                component="p"
                                fontWeight={800}
                                fontSize={20}
                                color="primary"
                            >
                                Estimilo
                            </Typography>
                            <Box
                                sx={{
                                    ml: 1,
                                    px: 1,
                                    py: 0.5,
                                    bgcolor: "darkgreen",
                                    color: "white",
                                    borderRadius: 1,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                Beta
                            </Box>
                        </Link>
                    </Grid>

                </Grid>


            </Box>

            {/* Centered box with distinct background */}
            <Paper
                elevation={3}
                style={{
                    padding: '40px',
                    backgroundColor: '#1e1e2f',
                    borderRadius: '12px',
                    textAlign: 'center',
                    maxWidth: '500px',
                    width: '100%',
                }}
            >
                {/* Display error message if it exists */}
                {errorMessage && (
                    <Typography color="error" variant="body1" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                {/* Google SSO Section */}
                <Typography variant="h4" color="white" gutterBottom>
                  Sign up
                </Typography>

                <Box mb={3} mt={3}>
                    <Typography variant="body2" color="white" component="div" sx={{ textAlign: "left" }}>
                        <ul>
                            <li>For now, you can only sign up using your Google account.</li>
                            <li>Signing up is only required if you'll be your team's Administrator.</li>
                            <li>If you’re not your team’s Administrator, please ask them to create an account for you.
                            </li>
                            <li>Once you sign up, you can invite your team members. They can log in directly with Google
                                without needing to create their own accounts.
                            </li>
                        </ul>
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center" mb={4}>
                    <GoogleButton
                        type="dark"
                        onClick={handleGoogleLogin}
                    />
                </Box>

                {/* Link to Login */}
                <Box mt={6}>
                    <Typography variant="body2" color="white">
                        Already registered?{' '}
                        <Link to="/login" style={{ color: theme.palette.success.main, textDecoration: 'none' }}>
                            Log in
                        </Link>
                    </Typography>
                </Box>

            </Paper>

            {/* Footer */}
            <Box mt="auto" width="100%">
                <HomePageFooter/>
            </Box>
        </Grid>
    );
};

export default SignupPage;
