import React from "react";
import { Grid, Button, Typography, Box } from "@mui/material";
import ComingSoonTag from "./ComingSoonTag";

const Card = ({
  backgroundImage,
  theme,
  comingSoon,
  bigTitle,
  title,
  subtitle,
  disableSubtitleOnMD,
  description,
  descriptionList,
  customDescription,
  buttonTitle,
  height = "100%",
  mt,
  pt,
  mb,
  pb,
  ml,
  buttonClick,
}) => (
  <Grid
    sx={{
      backgroundColor: backgroundImage ? "transparent" : "#25222E",
      p: { xs: 2, md: 4 },
      pt: { xs: 2, md: pt },
      mx: { xs: 1, md: 3 },
      mt: { xs: 0, md: mt },
      mb,
      pb,
      borderRadius: 10,
      height,
      ml: { xs: 1, md: ml },
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    {comingSoon && <ComingSoonTag theme={theme} />}
    {bigTitle && (
      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: { xs: "30px", md: "60px" },
        }}
      >
        {bigTitle}
      </Typography>
    )}
    {title && (
      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "white",
          fontWeight: 700,
          textAlign: "left",
          fontSize: "28px",
          pt: { xs: 1, md: 2, lg: 5 },
        }}
      >
        {title}
      </Typography>
    )}
    {subtitle &&
      subtitle.split("\n").map((line, index) => (
        <Typography
          key={index}
          component="p"
          variant="p"
          sx={{
            color: "white",
            textAlign: "left",
            my: {
              xs: 0.5,
              md: 1,
            },
            fontSize: { xs: 14, md: 16 },
            lineHeight: 1.5,
            display: {
              xs: disableSubtitleOnMD ? "none" : "block",
              md: disableSubtitleOnMD ? "none" : "block",
              lg: "block",
            },
            fontFamily: `'Roboto', sans-serif`,
          }}
        >
          {line}
        </Typography>
      ))}
    {customDescription && (
      <Box
        sx={{
          color: "white",
          fontSize: "18px",
          fontFamily: `'Roboto', sans-serif`,
          mt: 2,
          lineHeight: 1.4,
        }}
        dangerouslySetInnerHTML={{ __html: customDescription }}
      />
    )}
    {description &&
      description.split("\n").map((line, index) => (
        <Typography
          key={index}
          component="p"
          variant="p"
          sx={{
            color: "white",
            textAlign: "left",
            my: { xs: 0.5, md: 1, lg: 2 },
            fontFamily: `'Roboto', sans-serif`,
            fontSize: { xs: "14px", md: "18px" },
            lineHeight: { md: 1, lg: 1.5 },
          }}
        >
          {line}
        </Typography>
      ))}
    {descriptionList &&
      descriptionList.map((item) => (
        <Box key={item.id} sx={{ display: "flex", gridRow: 1, gridColumn: 2 }}>
          <img
            src={item.icon}
            alt={`icon for ${item.text}`}
            style={{ marginRight: "10px" }}
          />
          <Typography
            component="p"
            variant="p"
            sx={{
              color: "white",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
              fontFamily: `'Roboto', sans-serif`,
            }}
          >
            {item.text}
          </Typography>
        </Box>
      ))}
    {buttonTitle && (
      <Button
        sx={{
          bgcolor: "#BB86FC",
          borderRadius: "10px",
          color: "white",
          fontWeight: 600,
          display: "flex",
          minWidth: { md: "230px", xs: "50px" },
          fontFamily: `'Roboto', sans-serif`,
          mt: 1.5,
          alignItems: "center",
        }}
        onClick={buttonClick}
      >
        {buttonTitle}
      </Button>
    )}
  </Grid>
);

export default Card;
