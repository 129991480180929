import React, { useEffect, useState } from 'react';
import {Grid, Button, TextField, Typography, Box, Paper, useTheme} from '@mui/material';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import HomePageFooter from '../Components/HomePageFooter';
import logo from '../images/logo.png';
import {inputStyle} from "../Components/DialogWithInput/DialogWithInput.style"; // Import the logo

import GoogleButton from 'react-google-button'
import UserManagementService from "../service/UserManagementService";
import SystemConfig from "../config/SystemConfig";

const ErrorPage = () => {


    const theme = useTheme();
    const [email, setEmail] = useState('');
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation(); // Hook to detect the current URL

    // State to hold the extracted estimateSessionUrl
    const [estimateSessionUrl, setEstimateSessionUrl] = useState(null);


    // Check for specific error messages (e.g., UserNotFound)
    useEffect(() => {

        const urlParam = searchParams.get('estimateSessionUrl');
        if (urlParam) {
            setEstimateSessionUrl(urlParam); // Store the extracted parameter in state
        }

        const errorParam = searchParams.get("error");

        if (errorParam === "UserNotFound") {
            setErrorMessage("No account found with the provided Google account.");
        }
        if (errorParam === "NoAdminUser") {
            setErrorMessage("No user was created. If you’re not your team’s Administrator, please ask them to create an account for you.");
        }
        if (errorParam === "GeneralError") { // Handle general unexpected errors
            setErrorMessage("An error occurred during login.");
        }

    }, [searchParams, location]);

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" bgcolor="#0F0819" height="100%">

            {/* Logo Section */}
            <Box textAlign="center" mb={2}>
                <img src={logo} alt="Estimilo Logo" style={{ height: '100px', marginBottom: '20px' }} />
            </Box>

            {/* Centered box with distinct background */}
            <Paper
                elevation={3}
                style={{
                    padding: '40px',
                    backgroundColor: '#1e1e2f',
                    borderRadius: '12px',
                    textAlign: 'center',
                    maxWidth: '500px',
                    width: '100%',
                }}
            >

                {/* Display error message if it exists */}
                {errorMessage && (
                    <Typography color="error" variant="body1" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                <Box mt={6}>
                    <Typography variant="body2" color="white">

                        <Link to="/" style={{ color: theme.palette.success.main, textDecoration: 'none' }}>
                            Go to Homepage
                        </Link>
                    </Typography>
                </Box>







            </Paper>

            {/* Footer */}
            <Box mt="auto" width="100%">
                <HomePageFooter/>
            </Box>
        </Grid>
    );
};

export default ErrorPage;
