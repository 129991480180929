import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LinkedInLogo from "@mui/icons-material/LinkedIn";
import HomePageFooter from "../../Components/HomePageFooter";
import imageNicolaeAndronic from "../../images/NicolaeAndronic.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";

const CourseEstimatesInAgileSoftwareDevelopmentInPerson = () => {
  return (
    <Grid container bgcolor="#0F0819" height="100%" overflow="auto">
      <ThemedHeaderMenu />

      {/* Course Title Section */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 10 }}
      >
        <Typography
          variant="h1"
          textAlign="center"
          sx={{
            color: "primary.light",
            fontWeight: 700,
            textAlign: "left",
            fontSize: "36px",
            pt: { xs: 1, md: 2, lg: 5 },
          }}
        >
          Course: Estimates in Agile Software Development (In-Person)
        </Typography>
      </Grid>

      {/* Course Overview Section */}
      <Grid
        container
        sx={{ py: 6, px: { xs: 2, md: 8 } }}
        justifyContent="center"
      >
        <Box width={{ xs: "100%", md: "80%" }}>
          <Typography variant="h4" color="primary.dark" mb={3}>
            Course Overview
          </Typography>
          <Typography
            component="p"
            variant="p"
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            In today’s dynamic software development landscape, reliable project
            estimates are crucial to ensure successful project delivery. This
            course equips your team with a comprehensive understanding of key
            estimation concepts, the most effective Agile estimation techniques,
            and industry best practices. Through hands-on activities and
            real-world case studies, participants will leave equipped with
            practical skills to apply immediately to their projects.
          </Typography>
          <Typography variant="h6" color="primary.dark">
            Who Is This Course For?
          </Typography>
          <List
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <ListItem>
              <ListItemText primary="✔ Software Developers working in Agile teams" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Junior Project Managers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Project Managers transitioning from Waterfall to Agile" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Scrum Masters, Product Owners, etc." />
            </ListItem>
          </List>
          <Typography variant="h6" color="primary.dark">
            By the end of the course, participants will be able to:
          </Typography>
          <List
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <ListItem>
              <ListItemText primary="Understand the fundamental principles of Agile estimation." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Apply estimation techniques in Scrum and Kanban environments." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Improve the ability to communicate estimates to business stakeholders." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Utilize tools and best practices to improve estimation accuracy in own software projects." />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Course Content Section */}
      <Grid
        container
        sx={{ py: 6, px: { xs: 2, md: 8 } }}
        justifyContent="center"
      >
        <Box width={{ xs: "100%", md: "80%" }}>
          <Typography variant="h4" color="primary.dark" mb={3}>
            Course Content
          </Typography>
          <List
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <ListItem>
              <ListItemText primary="1. Introduction to Agile Estimation" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                Understand the importance of estimation in Agile environments.
                Learn the key concepts that lay the foundation for accurate
                software project estimates.
              </Typography>
            </Box>

            <ListItem>
              <ListItemText primary="2. General Concepts of Time Estimates" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                Explore how estimates evolve over time, who should provide them,
                and the types of estimates used in software development.
              </Typography>
            </Box>

            <ListItem>
              <ListItemText primary="3. Agile Estimation Techniques" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                A introduction to Scrum and estimating using story-point.
                Hands-on learning with estimation methods like Planning Poker,
                Complexity Buckets, White Elephant Sizing, etc.
              </Typography>
            </Box>

            <ListItem>
              <ListItemText primary="4. Communicating Estimates" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                Learn how to communicate estimates effectively to stakeholders,
                using ranged estimates. Understand how to help business
                stakeholder prioritize features.
              </Typography>
            </Box>

            <ListItem>
              <ListItemText primary="5. Estimation in Kanban" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                Understand how estimation works in continuous delivery
                environments using metrics like Cycle Time and Lead Time.
              </Typography>
            </Box>

            <ListItem>
              <ListItemText primary="6. Best Practices & Wrap-up" />
            </ListItem>
            <Box pl={4}>
              <Typography>
                Discover best practices for improving estimates and handling
                common challenges in Agile estimation.
              </Typography>
            </Box>
          </List>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <strong>Note:</strong> detailed course agenda can be provided upon
            request.
          </Typography>
        </Box>
      </Grid>

      {/* Instructor Section */}
      <Grid
        container
        justifyContent="center"
        sx={{ py: 6, px: { xs: 2, md: 8 } }}
      >
        <Box width={{ xs: "100%", md: "80%" }}>
          <Typography variant="h4" color="primary.dark" mb={3}>
            Instructor
          </Typography>
          <img
            src={imageNicolaeAndronic}
            alt="Instructor - Nicolae Andornic"
            style={{ height: "200px" }}
          />
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <strong>Nicolae Andronic</strong>{" "}
            <a
              href="https://www.linkedin.com/in/nicolaeandronic"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInLogo color="primary.link" />
            </a>{" "}
            brings over 19 years of expertise in software development and
            project & team management, having collaborated with diverse teams
            across multiple industries. He is deeply committed to helping teams
            enhance their estimation processes, driving success in Agile
            environments.
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            With a strong background as a software developer, Nicolae obtained
            certifications in both traditional ({" "}
            <a
              href="https://www.pmi.org/certifications/project-management-pmp"
              target="_blank"
              rel="noopener noreferrer"
            >
              PMP
            </a>{" "}
            - from Project Management Institute) and Agile ({" "}
            <a
              href="https://www.scrum.org/assessments/professional-scrum-master-i-certification"
              target="_blank"
              rel="noopener noreferrer"
            >
              PSM I
            </a>{" "}
            - from Scrum.org) methodologies, enabling him to bridge the gap
            between Waterfall and Agile practices.
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            In addition to his technical and managerial expertise, Nicolae
            infuses creativity and energy into his training sessions. As a
            semi-professional improvisation theater actor, performing in various
            venues across Bucharest with the troupe{" "}
            <a
              href=" https://www.facebook.com/ImproMob"
              target="_blank"
              rel="noopener noreferrer"
            >
              ImproMob
            </a>{" "}
            , he incorporates spontaneity, engagement, and interactive
            activities into his workshops, making learning both enjoyable and
            effective.
          </Typography>
        </Box>
      </Grid>

      {/* Pricing Section */}
      <Grid
        container
        justifyContent="center"
        sx={{ py: 6, px: { xs: 2, md: 8 } }}
      >
        <Box width={{ xs: "100%", md: "80%" }}>
          <Typography variant="h4" color="primary.dark" mb={3}>
            Pricing
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            Base Price: <strong>starting at €2000</strong>
          </Typography>
          <Typography color="secondary.light" variant="h6" mb={2}>
            What’s Included:
          </Typography>
          <List
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <ListItem>
              <ListItemText primary="✔ In-person, full-day training (6-8 hours), adaptable based on depth of topics and discussion time. Training can also be split into two days (3-4 hours each)." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Up to 9 participants." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Delivered at your location in Bucharest, Romania." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Delivered in English or Romanian." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Customized to fit your company’s unique challenges and project requirements." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Post-training materials, such as handouts and access to the video version of the course." />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ One year premium access to estimation tools available on Estimilo.com." />
            </ListItem>
          </List>
          <Typography color="secondary.light" variant="h6" mb={2}>
            Additional Options:
          </Typography>
          <List
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <ListItem>
              <ListItemText primary="✔ Delivery in our training facility (University Square, Bucharest): €500" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ On-line delivery via a video conferencing platform: €500" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Follow-up session (a two-hour follow-up session to review estimate process implementation and answer additional questions): €500" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Additional participants: €200 per participant (up to 6 participants)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Delivery in other city in Romania:  €1000 + travel and accomodation costs" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Additional participants: €200 per participant (up to 6 participants)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="✔ Discounted rates for any future training" />
            </ListItem>
          </List>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            <strong>Note:</strong> for all prices, VAT is not included.
          </Typography>
        </Box>
      </Grid>

      {/* Contact Section */}
      <Grid
        container
        justifyContent="center"
        sx={{ py: 6, px: { xs: 2, md: 8 } }}
      >
        <Box width={{ xs: "100%", md: "80%" }}>
          <Typography variant="h4" color="primary.dark" mb={3}>
            Contact & Booking Information
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            Name: Nicolae Andronic
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            Email:{" "}
            <a href="mailto:hello@estimilo.com" style={{ color: "#BB86FC" }}>
              hello@estimilo.com
            </a>
          </Typography>
          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          >
            Phone: +40 743 188 963
          </Typography>

          <Typography
            sx={{
              color: "primary.light",
              textAlign: "left",
              my: { md: 1, lg: 1.5 },
            }}
          ></Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "#BB86FC",
              borderRadius: "10px",
              color: "white",
              fontWeight: 600,
              paddingLeft: "20px",
              paddingRight: "20px",
              minWidth: "230px",
              fontFamily: `'Roboto', sans-serif`,
            }}
            startIcon={<DownloadIcon />}
            href="/course_presentation.pdf"
            download="Course Presentation - Estimates in Agile Software Development.pdf"
          >
            Download the PDF presentation
          </Button>
        </Box>
      </Grid>

      <HomePageFooter />
    </Grid>
  );
};

export default CourseEstimatesInAgileSoftwareDevelopmentInPerson;
