import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: {
      story: props.story,
    },
  });
  const style = {
    // Outputs `translate3d(x, y, 0)`
    maxWidth: "400px",
    opacity: 0.8,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      id={props.id}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </div>
  );
}

export default Draggable;
