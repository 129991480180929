import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem, Snackbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteIcon from "@mui/icons-material/Delete";

import { DndContext } from "@dnd-kit/core";

import { api } from "../../service/http";
import Config, { apiUrls, delay } from "../../config/Config";

import WhiteElephantService from "../../service/WhiteElephant/WhiteElephantService";
import CreateParticipantModal from "../../Components/genericTool/CreateParticipantModal";
import EstimateSessionDataService from "../../service/EstimateSessionDataService";
import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";
import { isParticipantTheScrumMaster } from "../../Utils/Utils";
import WhiteElephantEstimationBoardButtons from "../../Components/WhiteElephantEstimationBoardButtons";
import Droppable from "../../Components/Droppable";
import StoriesListItem from "../../Components/genericTool/StoriesListItem";
import Draggable from "../../Components/Draggable";
import CreateItemModal from "../../Components/genericTool/CreateItemModal";
import ListCard from "../../Components/ListCard/ListCard";
import ParticipantsList from "../../Components/genericTool/WhiteElephantParticipantsList";
import HomePageFooter from "../../Components/HomePageFooter";
import WhiteElephantViewBoard from "./WhiteElephantViewBoard";
import DialogConfirm from "Components/DialogConfirm/DialogConfirm";
import RoundEndedComponent from "Components/WhiteElephantSizing/RoundEndedComponent";
import ImportItemsFromJiraModal from "Components/genericTool/ImportItemsFromJiraModal";
import PremiumContent from "Components/PremiumContent";

export default function WhiteElephantSizingScreen({
  participant,
  updateParticipantData,
  useVirtualScrumMaster,
  scaleValue,
}) {
  const theme = useTheme();
  let { url } = useParams();
  const estimateSessionUrl = url;
  const scaleToUse = Config.getScaleToUse(scaleValue);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedStoryToDelete, setSelectedStoryToDelete] = useState();

  const [stories, setStories] = useState([]);
  const [estimatedStories, setEstimatedStories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [currentParticipant, setCurrentParticipant] = useState(participant);

  const [parent, setParent] = useState(null);

  const [myTurn, setMyTurn] = useState(false);
  const [roundStarted, setRoundStarted] = useState(false);
  const [currentRoundStories, setCurrentRoundStories] = useState([]);
  const [currentlyEstimatingStory, setCurrentlyEstimatingStory] = useState();
  const [estimationRoundId, setEstimationRoundId] = useState();
  const [participantsPassed, setParticipantsPassed] = useState(0);
  const [roundEnd, setRoundEnd] = useState(false);
  const [givenEstimate, setGivenEstimate] = useState(null);
  const [draggedStory, setDraggedStory] = useState();
  const [boardLocked, setBoardLocked] = useState(false);

  // States for Snackbar
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // no participant means a new participant needs to be created
  const createNewParticipantEstimateSession = (participantName, role) => {
    // create the Participant to the Estimate Session with the role of TEAM MEMBER
    WhiteElephantService.createNewParticipantEstimateSession(
      estimateSessionUrl,
      participantName,
      role
    ).then((result) => {
      updateParticipantData({
        id: result.data,
        role: role,
        name: participantName,
      });
    });
  };

  const initializeEstimationRound = useCallback(
    async (storyId) => {
      const response = await EstimateSessionDataService.initializeRound(
        estimateSessionUrl,
        storyId
      );
      setEstimationRoundId(response.data);
    },
    [estimateSessionUrl]
  );

  const pickRandomItemToEstimate = useCallback(
    (stories) => {
      let randomInt = Config.getRandomInt(stories.length - 1);
      setCurrentlyEstimatingStory(stories[randomInt]);
      initializeEstimationRound(stories[randomInt].id);
    },
    [initializeEstimationRound]
  );

  // Heartbeat mechanism

  // the timestamp of the last update of the Estimate Session, which would require re-rendering
  const [latestUpdateTimestamp, setLatestUpdateTimestamp] = useState();

  const reCalculate = useCallback(async () => {
    // Loads from BE all Items in the Estimate Session
    const fetchData = async () => {
      const participantsFromApi = await WhiteElephantService.fetchParticipants(
        estimateSessionUrl
      );

      const participants = participantsFromApi.data;

      const currentParticipant = participants.filter((p) => {
        if (p.participantActionStatus === Config.PARTICIPANT_STATUS.ON_TURN) {
          setRoundStarted(true);
        }
        return participant.id === p.participant.id;
      })[0];

      const storiesFromApi = await api(
        `${apiUrls.retrieveAllItemsFromSession}/${estimateSessionUrl}`
      );
      let estimatedStories = [];
      let stories = [];
      storiesFromApi.data.map((s) =>
        s.estimate !== null ? estimatedStories.push(s) : stories.push(s)
      );

      setCurrentParticipant(currentParticipant);
      setStories(stories);
      setEstimatedStories(estimatedStories);
      setParticipants(participants);

      if (
        stories.length > 0 &&
        currentParticipant &&
        currentParticipant.participantActionStatus ===
          Config.PARTICIPANT_STATUS.ON_TURN
      ) {
        setCurrentRoundStories(stories);
        setBoardLocked(false);
        pickRandomItemToEstimate(stories);
        setMyTurn(true);
      } else if (
        currentParticipant &&
        currentParticipant.participantActionStatus !==
          Config.PARTICIPANT_STATUS.ON_TURN
      ) {
        setMyTurn(false);
        setCurrentlyEstimatingStory(undefined);
      }

      if (stories.length === 0) {
        const participantsPassed = participants.reduce((acc, current) => {
          if (
            current.participantActionStatus === Config.PARTICIPANT_STATUS.PASSED
          ) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
        setParticipantsPassed(participantsPassed);
        if (participantsPassed === participants.length) {
          setRoundEnd(true);
        }
        if (
          currentParticipant &&
          currentParticipant.participantActionStatus ===
            Config.PARTICIPANT_STATUS.ON_TURN
        ) {
          setCurrentRoundStories(stories);
          setBoardLocked(false);
          setMyTurn(true);
        }
      }
    };
    fetchData();
  }, [estimateSessionUrl, participant.id, pickRandomItemToEstimate]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api(`${apiUrls.heartbeatUrl}/${estimateSessionUrl}`);
      if (!latestUpdateTimestamp || result.data > latestUpdateTimestamp) {
        reCalculate();
        setLatestUpdateTimestamp(result.data);
      }
    };

    const interval = setInterval(() => {
      if (
        !(
          currentParticipant &&
          currentParticipant.participantActionStatus ===
            Config.PARTICIPANT_STATUS.ON_TURN
        )
      ) {
        fetchData();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [
    estimateSessionUrl,
    latestUpdateTimestamp,
    reCalculate,
    currentParticipant,
  ]);

  if (!participant) {
    return (
      <CreateParticipantModal
        openModal={true}
        handleChangeParticipantName={(name) => {
          createNewParticipantEstimateSession(name, Config.SCRUM_MASTER_ROLE);
        }}
      />
    );
  } else if (Object.keys(participant).length === 0) {
    return (
      <CreateParticipantModal
        openModal={true}
        handleChangeParticipantName={(name) => {
          createNewParticipantEstimateSession(
            name,
            Config.DEFAULT_PARTICIPANT_ROLE
          );
        }}
      />
    );
  }

  const deleteStory = (id) => {
    EstimateSessionDataService.deleteItemFromEstimateSession(
      estimateSessionUrl,
      id
    ).then(() => {
      setConfirmDeleteOpen(false);
      reCalculate();
    });
  };

  const firstParticipant = async () => {
    await WhiteElephantService.updateParticipantStatus(
      estimateSessionUrl,
      participant.id,
      Config.PARTICIPANT_STATUS.ON_TURN
    );
  };

  const startRound = async () => {
    await firstParticipant();
  };

  const goToNextParticipant = async () => {
    let currentParticipantIndex = 0;
    for (let i = 0; i < participants.length; i++) {
      const p = participants[i];
      if (
        currentParticipant &&
        p.participantActionStatus === Config.PARTICIPANT_STATUS.ON_TURN &&
        p.participant.id === currentParticipant.participant.id
      ) {
        currentParticipantIndex = i;
        break;
      }
    }
    if (currentParticipantIndex < participants.length - 1) {
      await WhiteElephantService.updateParticipantStatus(
        estimateSessionUrl,
        participants[currentParticipantIndex + 1].participant.id,
        Config.PARTICIPANT_STATUS.ON_TURN
      );
    } else if (currentParticipantIndex === participants.length - 1) {
      await WhiteElephantService.updateParticipantStatus(
        estimateSessionUrl,
        participants[0].participant.id,
        Config.PARTICIPANT_STATUS.ON_TURN
      );
    }
    setCurrentParticipant(undefined);
    setCurrentlyEstimatingStory(undefined);
    setGivenEstimate(null);
  };

  const passTurn = async () => {
    if (
      currentParticipant &&
      currentParticipant.participantActionStatus !==
        Config.PARTICIPANT_STATUS.PASSED
    ) {
      setCurrentParticipant({
        ...currentParticipant,
        participantActionStatus: Config.PARTICIPANT_STATUS.PASSED,
      });
      await WhiteElephantService.updateParticipantStatus(
        estimateSessionUrl,
        participant.id,
        Config.PARTICIPANT_STATUS.PASSED
      );
      if (participantsPassed < participants.length - 1) {
        goToNextParticipant();
      } else if (participantsPassed === participants.length - 1) {
        setMyTurn(false);
        setRoundEnd(true);
        setCurrentParticipant(undefined);
        setCurrentlyEstimatingStory(undefined);
      }
    }
  };

  const endTurn = async () => {
    if (givenEstimate === null && stories.length === 0) {
      passTurn();
      return;
    }
    if (currentParticipant) {
      await EstimateSessionDataService.giveEstimate(
        estimationRoundId,
        currentParticipant.participant.id,
        givenEstimate || 5
      );
      await delay(100);

      // workaround to give estimate from all participants so that consensus is matched
      participants.forEach(async (p) => {
        if (
          currentParticipant &&
          p.participant.id !== currentParticipant.participant.id
        ) {
          await EstimateSessionDataService.giveEstimate(
            estimationRoundId,
            p.participant.id,
            givenEstimate || 5
          );
          await delay(100);
        }
      });
      await delay(100);
      await EstimateSessionDataService.acceptConsensusEstimate(
        estimationRoundId
      );
      // end of workaround
      await WhiteElephantService.updateParticipantStatus(
        estimateSessionUrl,
        participant.id,
        Config.PARTICIPANT_STATUS.ESTIMATED
      );
      setParent(null);
      goToNextParticipant();
    }
  };

  const actionMethods = {
    startRound,
    passTurn,
    endTurn,
  };

  /*
   *  Draggable part
   */

  const handleDragEnd = async (a) => {
    const currentStory = a.active.data.current.story;
    if (currentStory) {
      setDraggedStory(currentStory);
      if (a.over === null) {
        await EstimateSessionDataService.giveEstimate(
          estimationRoundId,
          currentParticipant.participant.id,
          null
        );
        setGivenEstimate(null);
        setParent("droppable" + currentStory.id);
        setCurrentRoundStories(stories);
      } else {
        const draggedTo = a.over.id;
        const newEstimateValue = draggedTo.substring(9, draggedTo.length);
        setGivenEstimate(newEstimateValue);
        setParent(draggedTo);
        setCurrentRoundStories(stories.filter((s) => s.id !== currentStory.id));
        if (currentlyEstimatingStory) {
          setEstimatedStories(
            estimatedStories.filter((s) => s.id !== currentlyEstimatingStory.id)
          );
          setCurrentlyEstimatingStory({
            ...currentlyEstimatingStory,
            estimate: newEstimateValue,
          });
        }
      }
      setBoardLocked(true);
    }
  };

  const importItemsFromJira = async (jiraCustomJql) => {
    try {
      await EstimateSessionDataService.importItemsFromJira(
          estimateSessionUrl,
          jiraCustomJql
      );
      // Handle the response after the request is successful
      reCalculate();
      // Show success message
      setSuccessMessage("Items imported successfully!");
    } catch (error) {
      // Show error message
      setErrorMessage("Error importing items from Jira");
    }
  };

  // Handlers to close Snackbar
  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <Grid
      container
      height={1}
      sx={{ bgcolor: theme.palette.secondary.main }}
      overflow="auto"
    >
      <ThemedHeaderMenu themeSwitch />

      <Grid item xs={12} direction={"row"} container>
        <DndContext onDragEnd={handleDragEnd}>
          <Grid item xs={8}>
            {roundEnd && <RoundEndedComponent />}
            <WhiteElephantEstimationBoardButtons
              shouldShowStartButton={
                isParticipantTheScrumMaster(participant.role) &&
                stories.length > 0 &&
                !roundStarted
              }
              shouldShowEndTurnButton={myTurn && !roundEnd && roundStarted}
              shouldShowPassButton={myTurn && stories.length === 0}
              actionMethods={actionMethods}
            />
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              border={2}
              borderColor={theme.palette.background.light}
              bgcolor={theme.palette.background.light}
              borderRadius={2}
              p={1}
              m={1}
            >
              {myTurn &&
                scaleToUse.valuesMap.map((row) => (
                  <Droppable
                    id={"droppable" + row.value}
                    key={"droppable" + row.value}
                  >
                    <Grid
                      key={row.value}
                      item
                      id={"droppable" + row.value}
                      display={"flex"}
                      flexDirection={"column"}
                      alignContent={"center"}
                      alignItems={"center"}
                      mx={1}
                      minWidth={"20px"}
                    >
                      <Typography
                        variant={"h4"}
                        color={theme.palette.primary.main}
                      >
                        {row.label}
                      </Typography>
                      <Grid
                        minWidth={"120px"}
                        minHeight={{ md: "150px", lg: "300px" }}
                        display={"flex"}
                        flexDirection={"column"}
                        bgcolor={theme.palette.success.main}
                        p={1}
                      >
                        {estimatedStories.length > 0 &&
                          estimatedStories.map((s) => {
                            if (s.estimate === row.label) {
                              if (
                                currentlyEstimatingStory &&
                                currentlyEstimatingStory.id === s.id
                              ) {
                                return (
                                  <Draggable
                                    id={`drag${s.id}`}
                                    key={s.id}
                                    story={s}
                                  >
                                    <ListItem
                                      style={{
                                        borderColor: theme.palette.primary.main,
                                      }}
                                      sx={{
                                        flex: 1,
                                        display: "flex",
                                        border: 1,
                                        borderRadius: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <OpenWithIcon
                                        color="primary"
                                        sx={{ mr: 1 }}
                                      />
                                      <StoriesListItem
                                        currentParticipantId={participant.id}
                                        participants={participants}
                                        story={s}
                                      />
                                    </ListItem>
                                  </Draggable>
                                );
                              } else {
                                return (
                                  <ListItem
                                    key={"estimatedStories" + s.id}
                                    sx={{
                                      flex: 1,
                                      display: "flex",
                                      backgroundColor:
                                        theme.palette.success.main,
                                      maxHeight: "max-content",
                                    }}
                                  >
                                    {boardLocked ? (
                                      <StoriesListItem
                                        currentParticipantId={participant.id}
                                        participants={participants}
                                        story={s}
                                      />
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          setCurrentlyEstimatingStory(s);
                                          initializeEstimationRound(s.id);
                                        }}
                                      >
                                        <LockOpenIcon sx={{ mr: 1 }} />
                                        <StoriesListItem
                                          currentParticipantId={participant.id}
                                          participants={participants}
                                          story={s}
                                        />
                                      </Button>
                                    )}
                                  </ListItem>
                                );
                              }
                            } else {
                              return <div key={s.id}></div>;
                            }
                          })}
                        {parent === "droppable" + row.value ? (
                          <Draggable
                            id={`drag${draggedStory && draggedStory.id}`}
                            key={draggedStory && draggedStory.id}
                            story={draggedStory}
                          >
                            <ListItem
                              style={{
                                borderColor: theme.palette.primary.main,
                              }}
                              sx={{
                                flex: 1,
                                display: "flex",
                                border: 1,
                                borderRadius: "8px",
                                alignItems: "center",
                              }}
                            >
                              <OpenWithIcon sx={{ mr: 1 }} color="primary" />
                              <StoriesListItem
                                currentParticipantId={participant.id}
                                participants={participants}
                                story={draggedStory}
                              />
                            </ListItem>
                          </Draggable>
                        ) : (
                          <Grid
                            bgcolor={theme.palette.success.main}
                            flex={1}
                          ></Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Droppable>
                ))}

              {!myTurn && (
                <WhiteElephantViewBoard
                  estimatedStories={estimatedStories}
                  participant={participant}
                  participants={participants}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={4}>
            {!roundEnd &&
                <Box mt={3} display="flex" justifyContent="space-between">
                  <CreateItemModal />
                  <PremiumContent>
                    <ImportItemsFromJiraModal onSubmit={importItemsFromJira} />
                  </PremiumContent>
                </Box>
            }

            <ListCard title="Items to estimate">
              <List>
                {!myTurn &&
                  stories &&
                  stories.map((s) => {
                    if (
                      currentlyEstimatingStory &&
                      s.id === currentlyEstimatingStory?.id
                    ) {
                      return (
                        <Draggable id={`drag${s.id}`} key={s.id} story={s}>
                          <ListItem
                            sx={{
                              flex: 1,
                              backgroundColor: theme.palette.secondary.main,
                              display: "flex",
                            }}
                          >
                            <StoriesListItem
                              currentParticipantId={participant.id}
                              participants={participants}
                              story={s}
                            />
                          </ListItem>
                        </Draggable>
                      );
                    } else {
                      return (
                        <ListItem
                          key={s.id}
                          sx={{
                            display: "flex",
                            flex: 1,
                          }}
                        >
                          {isParticipantTheScrumMaster(participant.role) &&
                          !roundStarted ? (
                            <>
                              <IconButton
                                color="success"
                                onClick={() => {
                                  setConfirmDeleteOpen(true);
                                  setSelectedStoryToDelete(s);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : (
                            <></>
                          )}
                          <StoriesListItem
                            currentParticipantId={participant.id}
                            participants={participants}
                            story={s}
                          />
                        </ListItem>
                      );
                    }
                  })}

                {myTurn &&
                  currentRoundStories.map((s) => {
                    if (
                      currentlyEstimatingStory &&
                      s.id === currentlyEstimatingStory?.id
                    ) {
                      return (
                        <Draggable id={`drag${s.id}`} key={s.id} story={s}>
                          <ListItem
                            sx={{
                              flex: 1,
                              backgroundColor: theme.palette.secondary.main,
                              display: "flex",
                              border: 1,
                              borderRadius: "8px",
                              alignItems: "center",
                            }}
                          >
                            <>
                              <OpenWithIcon sx={{ mr: 1 }} />
                              <StoriesListItem
                                currentParticipantId={participant.id}
                                participants={participants}
                                story={s}
                              />
                            </>
                          </ListItem>
                        </Draggable>
                      );
                    } else {
                      return (
                        <ListItem
                          key={s.id}
                          sx={{
                            display: "flex",
                            flex: 1,
                          }}
                        >
                          <StoriesListItem
                            currentParticipantId={participant.id}
                            participants={participants}
                            story={s}
                          />
                        </ListItem>
                      );
                    }
                  })}
              </List>
            </ListCard>

            <ParticipantsList
              currentParticipant={participant}
              participants={participants}
              navigateToURL="/whiteElephantSizing"
            />
          </Grid>
        </DndContext>
      </Grid>
      {confirmDeleteOpen && (
        <DialogConfirm
          title={`Are you sure you want to remove item: ${selectedStoryToDelete?.name}?`}
          open={confirmDeleteOpen}
          onConfirm={() => deleteStory(selectedStoryToDelete.id)}
          onReject={() => {
            setConfirmDeleteOpen(false);
          }}
          confirmText="YES"
          rejectText="NO"
        />
      )}

      {/* Success Snackbar */}
      <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
      >
        <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
      >
        <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>


      <HomePageFooter />
    </Grid>
  );
}
