import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ColorModeContext } from "../App";
import HomePageHeaderMenu from "../Components/HomePageHeaderMenu";
import HomePageFooter from "../Components/HomePageFooter";
import HomePageTitle from "../Components/HomePageTitle";
import FullWidthImage from "../Components/FullWidthImage";
import FullWidthVideo from "../Components/FullWidthVideo";
import TitleWithPreTitle from "../Components/TitleWithPreTitle";
import Card from "../Components/Card";
import FullWidthCard from "../Components/FullWidthCard";

import weFocusBackgroudImage from "../images/weFocusBG.svg";
import weFocusEmptyCardImage from "../images/weFocusEmptyCard.svg";
import weFocus3rdCard from "../images/weFocus3rdCard.svg";
import weOfferCoursesImage from "../images/weOfferCoursesBg.svg";
import futureCoursesImage from "../images/futureCoursesBg.svg";
import complexityBucketImage from "../images/complexityBucketsEstimate.svg";
import planningPokerImage from "../images/planningPokerBg.svg";
import youGoToAgileImage from "../images/youGoToAgileBg.svg";
import videoScrumMasterImage from "../images/videoScrumMasterBg.svg";
import whiteElephantSizingImage from "../images/whiteElephantSizingBg.svg";
import oujaBoardEstimateImage from "../images/oujaBoardEstimateBg.svg";
import aiToolsGamesImage from "../images/aiToolsGamesBg.svg";
import aiBackground from "../images/aiBackground.svg";

import accurateEstimationsIcon from "../icons/accurateEstimationsIcon.svg";
import efficientProjectPlanningIcon from "../icons/efficientProjectPlanningIcon.svg";
import streamlinedWorkflowsIcon from "../icons/streamlinedWorkflowsIcon.svg";
import trainingResourcesIcon from "../icons/trainingResourcesIcon.svg";
import efficientConsensusIcon from "../icons/efficientConsensusIcon.svg";
import enhancedTeamCollabIcon from "../icons/enhancedTeamCollabIcon.svg";
import streamlinedDevelopmentIcon from "../icons/streamlinedDevelopmentProcessIcon.svg";
import efficiencyBoostIcon from "../icons/efficiencyBoostIcon.svg";
import improvedAccuracyIcon from "../icons/improvedAccuracyIcon.svg";
import consistencyAndUniformityIcon from "../icons/consistencyAndUniformity.svg";
import enhancedDecisionIcon from "../icons/enhancedDecisionIcon.svg";
import engagingEstimationIcon from "../icons/engagingEstimationIcon.svg";
import creativityAndCollabIcon from "../icons/creativityAndCollabIcon.svg";
import pricingBoxIcon from "../icons/pricingBoxIcon.svg";
import ContactForm from "../Components/ContactForm";

import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import UserManagementService from "../service/UserManagementService";

const HomePage = () => {
  let navigate = useNavigate();

  const videoRef = useRef(null);
  const handleScrollToLearning = () => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toolsRef = useRef(null);
  const handleScrollToTools = () => {
    if (toolsRef.current) {
      toolsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const contactUsRef = useRef(null);
  const handleScrollToContactUs = () => {
    if (contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const pricingRef = useRef(null);
  const handleScrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container bgcolor="#0F0819" height="100%" overflow="auto">
      <HomePageHeaderMenu
        themeSwitch={false}
        handleScrollToLearning={handleScrollToLearning}
        handleScrollToTools={handleScrollToTools}
        handleScrollToContactUs={handleScrollToContactUs}
        handleScrollToPricing={handleScrollToPricing}
      />
      <HomePageTitle />
      <FullWidthImage />

      {/*
          <ReviewCarousel/>
          */}

      <Grid container pt={4} rowSpacing={2} height={1}>
        <Grid item xs={12} md={6} height={{ xs: 1 / 2, md: 1 }}>
          <Card
            pb={10}
            backgroundImage={weFocusBackgroudImage}
            title="Streamlining Your Software  Project Estimations"
            description={`Through our unique suite of tools and services, we provide modern, integrated solutions to address the challenges of traditional estimation methods in the software development industry.`}
            descriptionList={[
              {
                icon: accurateEstimationsIcon,
                text: "Accurate Estimations",
                id: 1,
              },
              {
                icon: efficientProjectPlanningIcon,
                text: "Fast go/no-go decisions",
                id: 2,
              },
              {
                icon: streamlinedWorkflowsIcon,
                text: "Streamlined Workflows",
                id: 3,
              },
              {
                icon: trainingResourcesIcon,
                text: "Training Resources and Courses",
                id: 4,
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={6} item container rowSpacing={1} height={1}>
          <Grid
            item
            height={1 / 2}
            xs={12}
            display={{ xs: "none", md: "block" }}
          >
            <Card ml={0} backgroundImage={weFocusEmptyCardImage} />
          </Grid>
          <Grid
            item
            height={{ xs: 1 / 3, md: 1 / 2 }}
            xs={12}
            mb={{ xs: 0, md: -2 }}
          >
            <Card
              ml={0}
              backgroundImage={weFocus3rdCard}
              subtitle={`
              → Utilise advanced tools and methodologies to improve the precision of projects estimations \n 
              → Incorporate historical data, industry benchmarks, and expert input to enhance the reliability of estimates \n 
              → Leverage the power of AI to identify patterns and correlations that are not immediately obvious to humans`}
            />
          </Grid>
        </Grid>
      </Grid>

      <TitleWithPreTitle
        ref={toolsRef}
        preTitle="Tools & Features"
        title={`Tools to Streamline Project Estimation & Boost Efficiency`}
      />

      <Grid container pt={4} rowSpacing={2} height={1} mx={{ xs: 0, md: 2 }}>
        <Grid item xs={12} md={6} height={{ xs: 1 / 2, md: 1 }}>
          <Card
            bigTitle="Planning Poker"
            backgroundImage={planningPokerImage}
          />
        </Grid>
        <Grid item xs={12} md={6} container rowSpacing={1} height={1}>
          <Grid item height={1 / 2} xs={12}>
            <Card
              ml={0}
              pt={0}
              backgroundImage={youGoToAgileImage}
              title="Your go-to Agile estimating technique!"
              subtitle={`Gather your team, playfully vote on task efforts, and reach consensus swiftly. Inspired by the fun dynamics of card games, it's a surefire way to streamline estimate efforts!`}
              descriptionList={[
                {
                  icon: efficientConsensusIcon,
                  text: "Efficient Consensus Building",
                  id: 5,
                },
                {
                  icon: enhancedTeamCollabIcon,
                  text: "Enhanced Team Collaboration",
                  id: 6,
                },
                {
                  icon: streamlinedDevelopmentIcon,
                  text: "Streamlined Estimate Process",
                  id: 7,
                },
              ]}
              buttonTitle="Start Estimating Now"
              buttonClick={() => {
                navigate("/signup");
              }}
            />
          </Grid>
          <Grid
            item
            height={{ xs: 1 / 4, md: 1 / 2 }}
            xs={12}
            mb={{ xs: 0, md: -4 }}
          >
            <Card
              ml={0}
              backgroundImage={videoScrumMasterImage}
              title="Virtual Scrum Master"
              customDescription="<b>Meet Ben, your digital ally!</b> This virtual facilitator enhances your Agile estimation sessions by leveraging cutting-edge technology to provide real-time guidance and support, making it easier than ever to estimate your work effectively."
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container pt={4} mx={{ xs: 0, md: 2 }}>
        <FullWidthCard
          premiumPlan
          backgroundImage={whiteElephantSizingImage}
          height="600px"
          theme="dark"
          bigTitle="White Elephant Sizing"
          subtitle="Faster estimation technique focusing on visual and comparative sizing of tasks rather than numerical estimation."
          descriptionList={[
            {
              icon: efficiencyBoostIcon,
              text: "<b>Efficiency Boost:</b> Quick and intuitive process, especially useful for teams familiar with each other's work. ",
              id: 8,
            },
            {
              icon: improvedAccuracyIcon,
              text: "<b>Improved Engagement:</b> Encourages a common high-level understanding of task complexity and effort.",
              id: 9,
            },
          ]}
          alignText="left"
        />
      </Grid>

      <Grid container pt={4} mx={{ xs: 0, md: 2 }}>
        <FullWidthCard
          premiumPlan
          theme="dark"
          backgroundImage={complexityBucketImage}
          height={{ xs: "700px", md: "600px" }}
          bigTitle="Complexity Buckets Estimate"
          subtitle="Quick estimation technique for obtaining high-level estimates, allowing upper management to take fast go/no-go decisions."
          descriptionList={[
            {
              icon: consistencyAndUniformityIcon,
              text: "<b>Consistency and Uniformity:</b> Ideal for teams looking for a quick, high-level understanding of task complexity.",
              id: 9,
            },
            {
              icon: enhancedDecisionIcon,
              text: "<b>Enhanced Decision-making:</b> Reduces estimation time by avoiding detailed numerical discussions, thus allowing for fast decision process.",
              id: 10,
            },
          ]}
          alignText="right"
        />
      </Grid>

      <FullWidthVideo ref={videoRef} />

      <Grid container minHeight="550px" mb={8} mx={{ xs: 0, md: 2 }}>
        <Grid item xs={12} md={8} mt={6.3}>
          <Card
            backgroundImage={weOfferCoursesImage}
            bigTitle="Estimates in Agile Software Development"
            title="In this course, we'll equip you with essential tools, processes, and best practices to achieve accurate project estimates within Agile software development."
            description={`→ Estimating in Story Points\n→ Understanding Different Types of Estimates\n→ Estimation Techniques for Scrum Framework`}
            buttonTitle="View full course"
            buttonClick={() => {
              navigate("/signup");
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} pt={6}>
          <Card
            ml={0}
            backgroundImage={futureCoursesImage}
            theme="light"
            bigTitle="In-Person Training"
            description={`Ask for an in-person training on "Estimates in Agile Software Development" delivered to your company.
                \n
              Whether you are a Project Manager, a Software Developer or a C-level Manager looking to deepen your understanding of time estimates, this course is tailored for you.\n
              → In-person, full-day training \n 
              → Up to 9 participants \n 
              → Delivered at your location in Bucharest, Romania \n 
              → Delivered in English or Romanian \n 
              → Customized to fit your company’s unique challenges \n
              `}
            buttonTitle="View Details"
            buttonClick={() => {
              navigate("/course/EstimatesInAgileSoftwareDevelopmentInPerson");
            }}
          />
        </Grid>
      </Grid>

      <TitleWithPreTitle
        preTitle="Future releases"
        title={`New tools and features we're building`}
      />

              <Grid
                  container
                  pt={4}
                  rowSpacing={1}
                  height={{xs: 1, md: 1}}
                  mx={{xs: 0, md: 2}}
              >
                  <Grid
                      xs={12}
                      md={6}
                      item
                      container
                      rowSpacing={1}
                      height={{xs: 6 / 10, md: 1}}
                  >

      <Grid item height={{ xs: 6 / 10, md: 1 }} xs={12} mb={{ xs: 0 }}>
        <Card
          backgroundImage={aiToolsGamesImage}
          comingSoon
          disableSubtitleOnMD
          theme="dark"
          bigTitle="AI Tools, Games & Integrations"
          subtitle={`We are dedicated to improving project estimation and management with new tools and features that emphasize innovation and collaboration. By using advanced technology and focusing on user experience, we aim to enhance productivity and efficiency. Stay tuned for upcoming developments that will support your software development teams.`}
          description={`→ An AI Virtual Scrum Master to assist the estimate sessions \n 
            → Games designed to improve team estimation skills \n
              → Integration with Jira and other Project Management Software
              `}
        />
                      </Grid>
      </Grid>
      <Grid item xs={12} md={6} height={{ xs: 5 / 10, md: 1 }}>
        <Card
          backgroundImage={aiBackground}
          comingSoon
          ml={0}
          theme="light"
          bigTitle="AI Estimation Engine"
          description={`We’re developing a cutting-edge AI Estimation Engine designed to improve the accuracy of your estimates by leveraging your historical data, industry benchmarks, and expert insights.\n\n
            Your data privacy is our top priority! We are creating a Private AI Engine using Meta’s Llama 3, the most advanced openly available LLM to date. This engine will operate in a secure cloud environment and be trained exclusively with the data you provide, ensuring complete tenant data isolation. \n 
            Although its development is complex, we are dedicated to bringing this innovative feature to life. We recognize that this tool is still a work in progress and plan to offer it as a separate feature in the future. We appreciate your patience and understanding as we work towards this exciting advancement.`}
        />
      </Grid>
              </Grid>

      <Grid item xs={12} height="50px" />

      {/* Pricing and Feature Section */}
      <TitleWithPreTitle
        preTitle="Start Free, Upgrade Anytime"
        title="30-Day Free Trial with Full Access"
        ref={pricingRef}
      />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="stretch"
          sx={{ textAlign: "center" }}
        >
          {/* Free Plan */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 12,
              border: 1,
              borderWidth: 1,
              borderColor: "#BB86FC",
              color: "white",
              justifyContent: "flex-start",
              padding: "16px",
            }}
          >
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
              <Typography variant="h5" color="#BB86FC" gutterBottom>
                Free Plan
              </Typography>
              <img
                src={pricingBoxIcon}
                alt={`icon for ${pricingBoxIcon}`}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="h3" color="#BB86FC" gutterBottom>
                <b>Free forever</b>
              </Typography>

              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!UserManagementService.isUserAuthenticated() ? (
                  <Button
                    onClick={() => {
                      navigate("/signup");
                    }}
                    sx={{
                      backgroundColor: "#BB86FC",
                      borderRadius: "10px",
                      marginLeft: "20px",
                      color: "white",
                      fontWeight: 500,
                      fontFamily: `'Roboto', sans-serif`,
                      paddingLeft: { xs: "5px", md: "35px" },
                      paddingRight: { xs: "5px", md: "35px" },
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                    }}
                  >
                      Start Free Trial *
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    sx={{
                      backgroundColor: "#BB86FC",
                      borderRadius: "10px",
                      marginLeft: "20px",
                      color: "white",
                      fontWeight: 500,
                      fontFamily: `'Roboto', sans-serif`,
                      paddingLeft: { xs: "5px", md: "35px" },
                      paddingRight: { xs: "5px", md: "35px" },
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                    }}
                  >
                    View Dashboard
                  </Button>
                )}
              </Box>

                {!UserManagementService.isUserAuthenticated() &&
                    <Typography
                        variant="h6"
                        color="secondary"
                        gutterBottom
                        sx={{
                            color: "white",
                            fontWeight: 200,
                            fontSize: { xs: "12px", md: "12px", lg: "18px" },
                            pt: 1,
                        }}
                    >
                        *No Credit Card Required
                    </Typography>
                }

              <Divider sx={{ borderColor: "#BB86FC", borderWidth: 1, my: 2 }} />

              <Box>
                  <Typography
                      variant="h6"
                      color="secondary"
                      gutterBottom
                      sx={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: { xs: "18px", md: "24px", lg: "32px" },
                          pt: 5,
                      }}
                  >
                      General:
                  </Typography>
                  <List>
                      <ListItem>
                          <ListItemText primary="✔ Premium access for 30 days for free" />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="✔ Up to 4 team members" />
                      </ListItem>
                  </List>

                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    fontSize: { xs: "18px", md: "24px", lg: "32px" },
                    pt: 5,
                  }}
                >
                  Tools:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="✔ Planning Poker" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ Basic Virtual Scrum Master" />
                  </ListItem>
                </List>

                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    fontSize: { xs: "18px", md: "24px", lg: "32px" },
                    pt: 5,
                  }}
                >
                  Training:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="✔ Course: Estimates in Agile Software Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ On-demand Courses" />
                  </ListItem>
                </List>

                  <Typography
                      variant="h6"
                      color="secondary"
                      gutterBottom
                      sx={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: { xs: "18px", md: "24px", lg: "32px" },
                          pt: 5,
                      }}
                  >
                      Support:
                  </Typography>
                  <List>
                      <ListItem>
                          <ListItemText primary="✔ Basic support" />
                      </ListItem>
                  </List>

              </Box>
            </Box>
          </Grid>

          {/* Premium Plan */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 12,
              border: 1,
              borderWidth: 1,
              borderColor: "#BB86FC",
              color: "white",
              justifyContent: "flex-start",
              padding: "16px",
            }}
          >
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
              <Typography variant="h5" color="#BB86FC" gutterBottom>
                Premium Plan
              </Typography>
              <img
                src={pricingBoxIcon}
                alt={`icon for ${pricingBoxIcon}`}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="h3" color="#BB86FC" gutterBottom>
                 <b>$10/month per team</b>
              </Typography>

              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  {!UserManagementService.isUserAuthenticated() ? (
                      <Button
                          onClick={() => {
                              navigate("/signup");
                          }}
                          sx={{
                              backgroundColor: "#BB86FC",
                              borderRadius: "10px",
                              marginLeft: "20px",
                              color: "white",
                              fontWeight: 500,
                              fontFamily: `'Roboto', sans-serif`,
                              paddingLeft: { xs: "5px", md: "35px" },
                              paddingRight: { xs: "5px", md: "35px" },
                              display: "flex",
                              alignItems: "center",
                              width: "60%",
                          }}
                      >
                          Start Free Trial *
                      </Button>
                  ) : (
                      <Button
                          onClick={() => {
                              navigate("/dashboard");
                          }}
                          sx={{
                              backgroundColor: "#BB86FC",
                              borderRadius: "10px",
                              marginLeft: "20px",
                              color: "white",
                              fontWeight: 500,
                              fontFamily: `'Roboto', sans-serif`,
                              paddingLeft: { xs: "5px", md: "35px" },
                              paddingRight: { xs: "5px", md: "35px" },
                              display: "flex",
                              alignItems: "center",
                              width: "60%",
                          }}
                      >
                          View Dashboard
                      </Button>
                  )}
              </Box>

                {!UserManagementService.isUserAuthenticated() &&
                    <Typography
                        variant="h6"
                        color="secondary"
                        gutterBottom
                        sx={{
                            color: "white",
                            fontWeight: 200,
                            fontSize: { xs: "12px", md: "12px", lg: "18px" },
                            pt: 1,
                        }}
                    >
                        *No Credit Card Required
                    </Typography>
                }

              <Divider sx={{ borderColor: "#BB86FC", borderWidth: 1, my: 2 }} />

              <Box>

                  <Typography
                      variant="h6"
                      color="secondary"
                      gutterBottom
                      sx={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: { xs: "18px", md: "24px", lg: "32px" },
                          pt: 5,
                      }}
                  >
                      General:
                  </Typography>
                  <List>
                      <ListItem>
                          <ListItemText primary="✔ Try for free for 30 days with full-access " />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="✔ Up to 9 team members" />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="✔ Each additional team member: extra $1 per month" />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="✔ 10% discount for annual payments" />
                      </ListItem>
                  </List>

                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    fontSize: { xs: "18px", md: "24px", lg: "32px" },
                    pt: 5,
                  }}
                >
                  Tools:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="✔ Planning Poker" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ White Elephant Sizing" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ Complexity Buckets Estimate" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ History of Estimate Sessions" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ Integration with Jira" />
                  </ListItem>
                    <ListItem>
                        <ListItemText primary="✔ Virtual Scrum Master" />
                    </ListItem>
                </List>

                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    fontSize: { xs: "18px", md: "24px", lg: "32px" },
                    pt: 5,
                  }}
                >
                  Training:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="✔ Course: Estimates in Agile Software Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ Access to Monthly Live Course" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ On-demand Courses" />
                  </ListItem>
                </List>

                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    fontSize: { xs: "18px", md: "24px", lg: "32px" },
                    pt: 5,
                  }}
                >
                  Other Tools & Services:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="✔ AI Virtual Scrum Master  - not yet available" />
                    </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ AI Estimation Engine - not yet available" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="✔ Estimation Games - not yet available" />
                  </ListItem>
                </List>

                  <Typography
                      variant="h6"
                      color="secondary"
                      gutterBottom
                      sx={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: { xs: "18px", md: "24px", lg: "32px" },
                          pt: 5,
                      }}
                  >
                      Support:
                  </Typography>
                  <List>
                      <ListItem>
                          <ListItemText primary="✔ Priority support" />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="✔ On-demand 30 minutes online training session" />
                      </ListItem>
                  </List>

              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <TitleWithPreTitle
        ref={contactUsRef}
        preTitle="Contact us"
        title={`Write us a message`}
      />

      <ContactForm />

      <Grid item xs={12} height="50px" />

      <HomePageFooter />
    </Grid>
  );
};

export default HomePage;
