import React from "react";

import { Tooltip, Fab } from "@mui/material";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useTheme } from "@mui/material/styles";
import DialogWithInput from "../DialogWithInput/DialogWithInput";

export default function ImportItemsFromJiraModal({ onSubmit }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleValidation = (input) => {
    return "";
  };

  const handleSubmit = (value) => {
    onSubmit(value);
    setModalOpen(false);
  };

  return (
    <div style={{ top: "30px", position: "relative" }}>
      <Tooltip
        color="success"
        title="Import items from Jira"
        aria-label="import"
      >
        <Fab
          variant="extended"
          size="medium"
          style={{ color: theme.palette.secondary }}
          onClick={handleOpen}
        >
          <PlaylistAddIcon />
        </Fab>
      </Tooltip>

      <DialogWithInput
        onSubmit={handleSubmit}
        title="Import items to be estimated from Jira"
        inputLabel="Custom JQL"
        inputPlaceholder="Leave empty if not needed"
        buttonText="Import"
        buttonVariant="contained"
        open={isModalOpen}
        handleClose={handleClose}
        validateInput={handleValidation}
      />
    </div>
  );
}
