import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Button, Typography, TextField, Card, CardContent,  Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThemedHeaderMenu from '../Components/ThemedHeaderMenu';
import HomePageFooter from '../Components/HomePageFooter';
import EstimateSessionDataService from '../service/EstimateSessionDataService';

const UserPreferences = () => {
    const navigate = useNavigate();

    // State for each preference
    const [jiraBaseUrl, setJiraBaseUrl] = useState('');
    const [jiraEmail, setJiraEmail] = useState('');
    const [jiraApiToken, setJiraApiToken] = useState('');
    const [jiraProjectKey, setJiraProjectKey] = useState('');

    // States for Snackbar
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Effect to fetch current preferences on component mount
    useEffect(() => {
        const fetchPreferences = async () => {
            try {
                const response = await EstimateSessionDataService.getUserPreferences(); // Fetch all user preferences from backend
                const prefs = response.data;

                // Update state based on fetched preferences
                prefs.forEach(pref => {
                    switch (pref.preferenceKey) {
                        case 'JIRA_BASE_URL':
                            setJiraBaseUrl(pref.preferenceValue);
                            break;
                        case 'JIRA_EMAIL':
                            setJiraEmail(pref.preferenceValue);
                            break;
                        case 'JIRA_API_TOKEN':
                            setJiraApiToken(pref.preferenceValue);
                            break;
                        case 'JIRA_PROJECT_KEY':
                            setJiraProjectKey(pref.preferenceValue);
                            break;
                        default:
                            break;
                    }
                });
            } catch (err) {
                console.error('Error fetching preferences:');
            }
        };

        fetchPreferences();
    }, []);

    // Handler to save preferences to backend
    const handleSavePreferences = async () => {
        try {
            // Save each preference to the backend
            await EstimateSessionDataService.saveUserPreference('JIRA_BASE_URL', encodeURIComponent(jiraBaseUrl));
            await EstimateSessionDataService.saveUserPreference('JIRA_EMAIL', jiraEmail);
            await EstimateSessionDataService.saveUserPreference('JIRA_API_TOKEN', jiraApiToken);
            await EstimateSessionDataService.saveUserPreference('JIRA_PROJECT_KEY', jiraProjectKey);

            // Show success message
            setSuccessMessage('Preferences saved successfully!');

        } catch (err) {
            // Show error message
            setErrorMessage('Error saving preferences');
        }
    };

    // Handlers to close Snackbar
    const handleCloseSnackbar = () => {
        setSuccessMessage('');
        setErrorMessage('');
        if (successMessage) {
            // Navigate to Dashboard when the success message is closed
            navigate('/dashboard');
        }
    };

    const handleBackToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <Grid container height={1} sx={{ bgcolor: 'secondary.main' }} overflow="auto">
            {/* Header */}
            <ThemedHeaderMenu themeSwitch title="User Preferences" />

            {/* Content Section */}
            <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    User Preferences
                </Typography>

                <Grid container spacing={4}>
                    {/* Jira Base URL */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Jira Base URL</Typography>
                                <TextField
                                    fullWidth
                                    value={jiraBaseUrl}
                                    onChange={(e) => setJiraBaseUrl(e.target.value)}
                                    label="Jira Base URL"
                                    variant="outlined"
                                    margin="normal"
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Jira Email */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Jira Email</Typography>
                                <TextField
                                    fullWidth
                                    value={jiraEmail}
                                    onChange={(e) => setJiraEmail(e.target.value)}
                                    label="Jira Email"
                                    variant="outlined"
                                    margin="normal"
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Jira API Token */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Jira API Token</Typography>
                                <TextField
                                    fullWidth
                                    value={jiraApiToken}
                                    onChange={(e) => setJiraApiToken(e.target.value)}
                                    label="Jira API Token"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Jira Project Key */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Jira Project Key</Typography>
                                <TextField
                                    fullWidth
                                    value={jiraProjectKey}
                                    onChange={(e) => setJiraProjectKey(e.target.value)}
                                    label="Jira Project Key"
                                    variant="outlined"
                                    margin="normal"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Save Button */}
                <Box mt={3} display="flex" justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={handleSavePreferences}>
                        Save Preferences
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleBackToDashboard}>
                        Back to Dashboard
                    </Button>
                </Box>

            </Container>

            {/* Success Snackbar */}
            <Snackbar open={Boolean(successMessage)} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            {/* Footer */}
            <HomePageFooter />
        </Grid>
    );
};

export default UserPreferences;
