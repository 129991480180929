import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton, ListItem, List, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ListCard from "../ListCard/ListCard";
import DialogConfirm from "../DialogConfirm/DialogConfirm";
import Config from "../../config/Config";
import { isParticipantTheScrumMaster } from "../../Utils/Utils";
import EstimateSessionDataService from "../../service/EstimateSessionDataService";

const WhiteElephantParticipantsList = ({
  participants,
  currentParticipant,
  navigateToURL,
}) => {
  let { url } = useParams();
  const estimateSessionUrl = url;
  let navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [participantIdToDelete, setParticipantIdToDelete] = React.useState();

  //handles opening of the Delete Participant dialog
  const handleDeleteParticipantDialogOpen = (id) => {
    setDeleteDialogOpen(true);
    setParticipantIdToDelete(id);
  };

  const handleDeleteParticipant = async () => {
    setDeleteDialogOpen(false);
    await EstimateSessionDataService.deleteParticipantFromEstimateSession(
      estimateSessionUrl,
      participantIdToDelete
    );
    if (currentParticipant.id === participantIdToDelete) {
      navigate(navigateToURL);
    }
  };

  return (
    <ListCard title="Estimators">
      <List>
        {participants.length > 0 &&
          participants.map((participant) => {
            let p = participant.participantActionStatus
              ? participant.participant
              : participant;
            return (
              <ListItem
                key={p.id}
                style={{ borderColor: "#BB86FC" }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  border:
                    participant.participantActionStatus ===
                    Config.PARTICIPANT_STATUS.ON_TURN
                      ? 0.5
                      : 0,
                }}
              >
                <div
                  style={{
                    textOverflow: "ellipsis",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {isParticipantTheScrumMaster(currentParticipant.role) ? (
                    <IconButton
                      color="success"
                      onClick={() => handleDeleteParticipantDialogOpen(p.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  <Typography sx={{ alignSelf: "center", ml: 1 }}>
                    {currentParticipant.id === p.id ? (
                      <b> {p.name} </b>
                    ) : (
                      p.name
                    )}
                    {isParticipantTheScrumMaster(p.role)
                      ? "(Scrum Master)"
                      : ""}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
      </List>
      <DialogConfirm
        title={
          participantIdToDelete === currentParticipant.id
            ? "Are you sure you want to remove yourself?"
            : "Are you sure you want to delete this estimator?"
        }
        open={deleteDialogOpen}
        onConfirm={handleDeleteParticipant}
        onReject={() => {
          setDeleteDialogOpen(false);
        }}
        confirmText="YES"
        rejectText="NO"
      />
    </ListCard>
  );
};

export default WhiteElephantParticipantsList;
