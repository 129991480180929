import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function SignupSuccess() {
    const navigate = useNavigate();
    const location = useLocation();

    // Function to store the token in localStorage and navigate to dashboard
    const handleLoginSuccess = (token) => {
        sessionStorage.setItem('jwtToken', token); // Store the token in localStorage
        navigate('/dashboard'); //
    };

    useEffect(() => {
        // Parse the token from the URL query parameters
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token'); // Extract the JWT token from the URL

        if (token) {
            handleLoginSuccess(token); // Call handleLoginSuccess to store the token
        }
    }, [location]);

    return (
        <div>
            <h1>Logging in...</h1>
        </div>
    );
}
