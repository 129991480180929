import React from "react";

import { Grid, ListItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Config from "../../config/Config";
import StoriesListItem from "../../Components/genericTool/StoriesListItem";

const WhiteElephantViewBoard = ({
  estimatedStories,
  participant,
  participants,
}) => {
  const theme = useTheme();

  return Config.scaleValuesMap[1].valuesMap.map((row) => (
    <Grid
      key={row.value}
      item
      id={"droppable" + row.value}
      display={"flex"}
      flexDirection={"column"}
      alignContent={"center"}
      alignItems={"center"}
      mx={1}
      minWidth={"20px"}
    >
      <Typography variant={"h4"} color={theme.palette.primary.main}>
        {row.label}
      </Typography>
      <Grid
        minWidth={"120px"}
        minHeight={{ md: "150px", lg: "300px" }}
        display={"flex"}
        flexDirection={"column"}
        bgcolor={theme.palette.secondary.main}
      >
        {estimatedStories.length > 0 &&
          estimatedStories.map((s) => {
            if (s.estimate === row.label) {
              return (
                <ListItem
                  sx={{
                    flex: 1,
                    display: "flex",
                    maxHeight: "max-content",
                  }}
                  key={s.id}
                >
                  <StoriesListItem
                    currentParticipantId={participant.id}
                    participants={participants}
                    story={s}
                  />
                </ListItem>
              );
            } else {
              return <div key={s.id}></div>;
            }
          })}
      </Grid>
    </Grid>
  ));
};

export default WhiteElephantViewBoard;
