import React from "react";
import { Navigate, useParams } from "react-router-dom";
import UserManagementService from "../service/UserManagementService";

const ProtectedRoute = ({ element }) => {
  const { url } = useParams(); // Extract the :url parameter

  if (UserManagementService.isUserAuthenticated()) {
    // If user is authenticated, render the element (the protected component)
    return element;
  } else {
    // If user is not authenticated and "url" (Estimate Session URL) exists, redirect to login with estimateSessionUrl as query parameter
    const redirectUrl = url
      ? `/login?estimateSessionUrl=${encodeURIComponent(url)}`
      : "/login";

    return <Navigate to={redirectUrl} replace />;
  }
};

export default ProtectedRoute;
