import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { Grid, Typography, Button, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../App";
import logo from "../images/logo.png";
import DialogConfirm from "./DialogConfirm/DialogConfirm";
import UserManagementService from "../service/UserManagementService";

const ThemedHeaderMenu = ({
  themeSwitch,
  title,
  handleScrollToLearning,
  handleScrollToTools,
  handleScrollToContactUs,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const [confirmLogoutOpen, setConfirmLogoutOpen] = React.useState(false);
  const handleLogoutUser = () => {
    // Remove the JWT token from sessionStorage
    sessionStorage.removeItem("jwtToken");

    setConfirmLogoutOpen(false);

    // Redirect to home page
    navigate("/");
  };

  return (
    <Grid
      container
      item
      xs={12}
      mx={{ xs: 1, md: 5 }}
      mt={4}
      bgcolor={theme.palette.background.light}
      height={80}
      sx={{ borderRadius: "20px" }}
      px={{ xs: 1, md: 3 }}
    >
      <Grid container>
        <Grid item xs={1} alignContent="center" justifyContent="center">
          <Link
            to={
              UserManagementService.isUserAuthenticated() ? "/dashboard" : "/"
            }
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              width: "100%",
            }}
          >
            <img src={logo} alt="Estimilo Logo" style={{ height: "50px" }} />
            <Typography
              variant="p"
              component="p"
              fontWeight={800}
              fontSize={20}
              color="primary"
            >
              Estimilo
            </Typography>
            <Box
              sx={{
                ml: 1,
                px: 1,
                py: 0.5,
                bgcolor: "darkgreen",
                color: "white",
                borderRadius: 1,
                fontSize: 12,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Beta
            </Box>
          </Link>
        </Grid>
        <Grid item xs={1} md={3} />
        <Grid
          container
          item
          xs={10}
          md={8}
          alignContent="center"
          justifyContent="flex-end"
        >
          {!UserManagementService.isUserAuthenticated() ? (
            <Button
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                bgcolor: "#BB86FC",
                borderRadius: "10px",
                marginLeft: "20px",
                color: "white",
                fontWeight: 500,
                fontFamily: `'Roboto', sans-serif`,
                paddingLeft: { xs: "5px", md: "35px" },
                paddingRight: { xs: "5px", md: "35px" },
                display: "flex",
                alignItems: "center",
              }}
            >
              Login
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  setConfirmLogoutOpen(true);
                }}
                sx={{
                  bgcolor: "#BB86FC",
                  borderRadius: "10px",
                  marginLeft: "20px",
                  color: "white",
                  fontWeight: 500,
                  fontFamily: `'Roboto', sans-serif`,
                  paddingLeft: { xs: "5px", md: "35px" },
                  paddingRight: { xs: "5px", md: "35px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Logout
              </Button>
              <DialogConfirm
                title={`Are you sure you want to logout?`}
                open={confirmLogoutOpen}
                onConfirm={() => handleLogoutUser()}
                onReject={() => {
                  setConfirmLogoutOpen(false);
                }}
                confirmText="YES"
                rejectText="NO"
              />
            </>
          )}
          {themeSwitch && (
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="primary"
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThemedHeaderMenu;
