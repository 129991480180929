import React, { useState, useEffect } from "react";

import { Container, Grid, Typography } from "@mui/material";
import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";
import HomePageFooter from "../../Components/HomePageFooter";

import { useTheme } from "@mui/material/styles";
import EstimateSessionHistoryCollapsibleTable from "./EstimateSessionHistoryCollapsibleTable";
import EstimateSessionDataService from "../../service/EstimateSessionDataService";

const EstimateSessionHistory = () => {
  const theme = useTheme();

  const [estimateSessions, setEstimateSessions] = useState([]); // State to store the fetched data

  const retrieveEstimateSessionHistory = async () => {
    const response =
      await EstimateSessionDataService.retrieveAllEstimateSessions();
    setEstimateSessions(response.data);
  };

  // Fetch Estimate Sessions on component mount
  useEffect(() => {
    retrieveEstimateSessionHistory();
  }, []);

  return (
    <Grid
      container
      height={1}
      sx={{ bgcolor: theme.palette.secondary.main }}
      overflow="auto"
    >
      <ThemedHeaderMenu themeSwitch title="Estimate Session History" />

      <Container
        maxWidth="lg"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <Typography variant="h4" gutterBottom>
          History of Estimate Sessions
        </Typography>

        <EstimateSessionHistoryCollapsibleTable
          estimateSessions={estimateSessions}
        />
      </Container>

      <HomePageFooter />
    </Grid>
  );
};

export default EstimateSessionHistory;
