import React from "react";

import { IconButton, Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import DialogConfirm from "../DialogConfirm/DialogConfirm";

import { isParticipantTheScrumMaster } from "../../Utils/Utils";
import EstimateSessionDataService from "service/EstimateSessionDataService";
import { useParams } from "react-router-dom";

const StoriesListItem = ({ currentParticipantId, participants, story }) => {
  let { url } = useParams();
  const estimateSessionUrl = url;
  const theme = useTheme();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [selectedStoryToDelete, setSelectedStoryToDelete] = React.useState();

  const currentParticipant = participants?.filter(
    (p) => p.id === currentParticipantId
  );

  const currentParticipantRole = currentParticipant[0]?.role;

  const handleDeleteStory = async (id) => {
    await EstimateSessionDataService.deleteItemFromEstimateSession(
      estimateSessionUrl,
      id
    );
    setConfirmDeleteOpen(false);
  };

  if (!story) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {isParticipantTheScrumMaster(currentParticipantRole) ? (
        <IconButton
          color="success"
          onClick={() => {
            setSelectedStoryToDelete(story);
            setConfirmDeleteOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        ""
      )}
      <Typography
        component="p"
        alignContent="center"
        sx={{ color: theme.palette.primary.main }}
      >
        {story.name}
      </Typography>
      {selectedStoryToDelete && (
        <DialogConfirm
          title={`Are you sure you want to remove item: ${selectedStoryToDelete?.name}?`}
          open={confirmDeleteOpen}
          onConfirm={() => handleDeleteStory(selectedStoryToDelete?.id)}
          onReject={() => {
            setConfirmDeleteOpen(false);
          }}
          confirmText="YES"
          rejectText="NO"
        />
      )}
    </div>
  );
};

export default StoriesListItem;
