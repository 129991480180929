import React from "react";

import { Tooltip, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogWithInput from "../DialogWithInput/DialogWithInput";
import { useParams } from "react-router-dom";
import EstimateSessionDataService from "../../service/EstimateSessionDataService";

export default function CreateItemModal() {
  let { url } = useParams();
  const estimateSessionUrl = url;

  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter some text"; // Basic non-empty validation
    }
    return "";
  };

  const createNewStory = async (itemName) => {
    await EstimateSessionDataService.createNewItemInEstimateSession(
      estimateSessionUrl,
      itemName
    );
  };

  const handleSubmit = (value) => {
    createNewStory(value);
    setModalOpen(false);
  };

  return (
    <div style={{ top: "30px", position: "relative" }}>
      <Tooltip
        color="success"
        title="Add an item to be estimated"
        aria-label="add"
      >
        <Fab
          sx={{ ml: 2 }}
          variant="extended"
          size="medium"
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <DialogWithInput
        onSubmit={handleSubmit}
        title="New item"
        inputLabel="Add item to be estimated"
        inputPlaceholder="Name or description of the item"
        buttonText="Save"
        buttonVariant="contained"
        open={isModalOpen}
        handleClose={handleClose}
        validateInput={handleValidation}
      />
    </div>
  );
}
